export enum LsEnv {
  Dev = 'dev',
  Prod = 'prod',
  Uat = 'uat',
  Stag = 'stag',
}
interface IEnvMap {
  apps: {
    atlas_base_url: string;
    eligibilites_url: string;
    token: string;
  };
  domain: string;
  request_codes: {
    Unauthorized: number;
    Forbidden: number;
    TooManyRequests: number;
    InternalServerError: number;
  };
  gtm: {
    auth: string;
    gtmId: string;
    preview: string;
  };
  timeout: number;
}

const getLsEnv = (): LsEnv => {
  if (realmDomain.includes('uat-')) {
    return LsEnv.Uat;
  } else if (realmDomain.includes('dev-')) {
    return LsEnv.Dev;
  } else if (realmDomain.includes('stag-')) {
    return LsEnv.Stag;
  } else {
    return LsEnv.Prod;
  }
};

const defaultEnv = {
  apps: {
    atlas_base_url: 'https://atlas-queries.api.dev-legalshield.com',
    eligibilites_url: 'https://eligibilities.api.dev-legalshield.com',
    token:
      'sso-jwt eyJhbGciOiJSUzI1NiIsImtpZCI6IjBlMjZjMmZhLTU4MTMtNDM0Mi05MTU2LWIzMzhhMDVmZWFmNCIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1OWEwODk3My1hZjg1LTQ4MWEtYmUzYi0wMDk0OTJlYjJjZmIiLCJpYXQiOjE2NjM5MTUwNjUsInJhdCI6MTY2MzkxNTA2NSwic3ViIjoiM2EzZDIyYWMtZTdjYy0xMWVhLWFkYzEtMDI0MmFjMTIwMDAyIiwic3ViX3R5cGUiOiJNYWNoaW5lIiwiaXNzIjoiaHR0cDovL2lkZW50aXRpZXMuYXBpLmRldi1sZWdhbHNoaWVsZC5jb20iLCJtYWsiOiJsb2dpbi1zZXJ2aWNlIiwibWZ0IjpmYWxzZSwiZmFjdG9ycyI6eyJrX2FwaWtleSI6MTY2MzkxNTA2NX19.ump1y7xcdK-0jiWfuKRgiqnqPRGEsWTVCGigdElEy98lQXjK6M-ElAgQWfHmUKX-BjmpE_W5ako2Em-ln9Y0uEFSq35CrjuvjCjX61gdTt0dOm0_YUSKn3ch8cFwuAyqxkNrNvyvUCHHu4C_DKmgaSMCg6iK1M1b79QgObuDOBIh-RicseUlE-57dp9H6F_7OPCUg7gPaRaBq-m516QT5v-Q8sxy9lwd6tgaDVYRhdSb_ZmeavsXBXp4fOt_59r5cZuhth7LC3TKKKOXeJT0-blJpJiabIe3AMLvDq30XL-cGYxZQWXQKAsWc4D35xr4drogjoDNdQroIfTgzxiEqA',
  },
  domain: realmDomain,
  gtm: {
    auth: '3Xc1miVyp0KakXocOPvRzA',
    gtmId: 'GTM-T9SMVMQ',
    preview: 'env-1',
  },
  request_codes: {
    Forbidden: 403,
    InternalServerError: 500,
    TooManyRequests: 429,
    Unauthorized: 401,
  },
  timeout: 5000,
} as IEnvMap;
const currentEnv = Object.assign({}, defaultEnv);
const lsEnv = getLsEnv();
switch (lsEnv) {
  case 'dev':
    currentEnv.gtm = {
      auth: 'ytjelxx0a_TU46dOuCxuyg',
      gtmId: 'GTM-T9SMVMQ',
      preview: 'env-23',
    };
    break;
  case 'uat':
    currentEnv.gtm = {
      auth: 'xm_32v5i1se6FMPLv7vZiw',
      gtmId: 'GTM-T9SMVMQ',
      preview: 'env-24',
    };
    break;
  case 'stag':
    currentEnv.gtm = {
      auth: 'ytjelxx0a_TU46dOuCxuyg',
      gtmId: 'GTM-T9SMVMQ',
      preview: 'env-23',
    };
    break;
}

const associateProductFamily = 'Associate';
const legalProductFamily = 'Legal';
const identityProductFamily = 'Identity';

const productFamily = {
  associate: associateProductFamily,
  identity: identityProductFamily,
  legal: legalProductFamily,
};

export default {
  currentEnv: currentEnv,
  getLsEnv: getLsEnv,
  productFamily: productFamily,
};

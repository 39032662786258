import type NewRelic from 'newrelic';

import { logger } from './logger';

/** Send the error to newrelic using the browser agent */
export function handleError(error: unknown, customAttributes?: CustomNoticeErrorAttributes) {
  logger.error(error, customAttributes);
  if (typeof window.newrelic !== 'undefined') {
    window.newrelic.noticeError(error instanceof Error ? error : new Error(`${error}`), customAttributes);
  } else if (!window.location.port) {
    // only print this console error when in deployed environments + newrelic isn't setup correctly
    logger.error('Could not send the above error to newrelic');
  }
}

/** extracts the type of newrelic.noticeError's customAttributes argument */
export type CustomNoticeErrorAttributes = Parameters<(typeof NewRelic)['noticeError']>[1];

export * as ErrorUtil from './error.util';

export const dd_term_strings: Record<string, Record<string, string>> = {
  ANNUAL: {
    'en-ca': 'Annually',
    'en-us': 'Annually',
    'es-us': 'Anual',
    'fr-ca': 'Annuel',
  },
  BIANNUAL: {
    'en-ca': 'Biannually',
    'en-us': 'Biannually',
    'es-us': 'Semestral',
    'fr-ca': 'Semestriel',
  },
  DAILY: {
    'en-ca': 'Daily',
    'en-us': 'Daily',
    'es-us': 'Diario',
    'fr-ca': 'Quotidien',
  },
  MONTHLY: {
    'en-ca': 'Plan Monthly Totals:',
    'en-us': 'Plan Monthly Totals:',
    'es-us': 'Totales Mensuales Del Plan:',
    'fr-ca': 'Totaux Mensuels Du Plan:',
  },
  PAY_PERIOD: {
    'en-ca': 'Per Pay Period',
    'en-us': 'Per Pay Period',
    'es-us': 'Por período de pago',
    'fr-ca': 'Par période de paie',
  },
  QUARTERLY: {
    'en-ca': 'Quarterly',
    'en-us': 'Quarterly',
    'es-us': 'Trimestral',
    'fr-ca': 'Trimestriel',
  },
  WEEKLY: {
    'en-ca': 'Weekly',
    'en-us': 'Weekly',
    'es-us': 'Semanal',
    'fr-ca': 'Hebdomadaire',
  },
};

export const per_term_strings: Record<string, Record<string, string>> = {
  ANNUAL: {
    'en-ca': '/year',
    'en-us': '/year',
    'es-us': '/año',
    'fr-ca': '/an',
  },
  BIANNUAL: {
    'en-ca': '/biannual',
    'en-us': '/biannual',
    'es-us': '/semestral',
    'fr-ca': '/semestriel',
  },
  DAILY: {
    'en-ca': '/day',
    'en-us': '/day',
    'es-us': '/día',
    'fr-ca': '/jour',
  },
  MONTHLY: {
    'en-ca': '/month',
    'en-us': '/month',
    'es-us': '/mes',
    'fr-ca': '/mois',
  },
  ONCE: {
    'en-ca': '/one time fee',
    'en-us': '/one time fee',
    'es-us': '/cargo único',
    'fr-ca': '/frais unique',
  },
  PAY_PERIOD: {
    'en-ca': '/pay period',
    'en-us': '/pay period',
    'es-us': '/periodo de pago',
    'fr-ca': '/période de paie',
  },
  QUARTERLY: {
    'en-ca': '/quarterly',
    'en-us': '/quarterly',
    'es-us': '/trimestral',
    'fr-ca': '/trimestriel',
  },
  WEEKLY: {
    'en-ca': '/week',
    'en-us': '/week',
    'es-us': '/semana',
    'fr-ca': '/semaine',
  },
};

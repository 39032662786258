import React, { useMemo } from 'react';
import { PlanCardTierRadioButtonGroup } from '@legalshield/adonis-ux-framework';

import { PlanConfigurationSetters } from '../../routes/AppRoutes';

interface TiersProps {
  planConfigurationSetters: PlanConfigurationSetters;
  currentTermName: string;
  currentTierName: string;
  offerId: string;
  region: string;
  periodTerm?: string;
  setAndScrollToRegionError: () => void;
  onChangeDisabled: boolean;
}

export const Tiers: React.FC<TiersProps> = ({
  planConfigurationSetters,
  currentTermName,
  currentTierName,
  offerId,
  region,
  setAndScrollToRegionError,
  onChangeDisabled = false,
  periodTerm,
}) => {
  const availableTiers = useMemo(() => {
    const tiers = cartBuilderOfferData?.[offerId]?.products?.[0].merchandizedTiers || [];

    const productId = cartBuilderOfferData?.[offerId]?.products?.[0].id;
    const productVersion = cartBuilderOfferData?.[offerId]?.products?.[0]?.productVersion;
    const simpleProductData = cartBuilderSimpleProductDataMap[productId + '|' + productVersion?.toString()];

    if (!simpleProductData || !simpleProductData.tiers) return [];
    const options: { price: number; name: string; tierName: string }[] = [];
    for (let i = 0; i < tiers?.length; i++) {
      const tierNameFromCBOfferData = tiers?.[i]?.name || '';
      const tierNameFromSimpleProductData = simpleProductData.tiers[tierNameFromCBOfferData] || tierNameFromCBOfferData;
      let priceString = 0;
      if (currentTermName) {
        for (let j = 0; j < (tiers?.[i]?.prices?.length || 0); j++) {
          if (tiers?.[i]?.prices?.[j]?.billingPeriod === currentTermName) {
            priceString = tiers?.[i]?.prices?.[j]?.initialListPrice || 0;
            options.push({
              name: tierNameFromSimpleProductData,
              price: priceString,
              tierName: tierNameFromCBOfferData,
            });
          }
        }
      }
    }
    return options;
  }, [currentTermName, offerId]);

  const onTierChange = (selectedTierName: string): void => {
    if (!region) {
      return setAndScrollToRegionError();
    }
    const selectedTierData = availableTiers?.find((tier) => tier.name === selectedTierName);
    if (selectedTierData?.tierName === currentTierName) {
      return;
    }
    planConfigurationSetters?.setCurrentTierName(selectedTierData?.tierName || '');
  };

  return (
    (((availableTiers && availableTiers?.length) || 0) > 1 && (
      <PlanCardTierRadioButtonGroup
        availableTiers={availableTiers || []}
        selectedTier={availableTiers?.find((item) => item.tierName === currentTierName)?.name || ''}
        handleTierClick={onChangeDisabled ? () => {} : onTierChange}
        periodTerm={periodTerm}
      />
    )) || <></>
  );
};

import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Spinner } from '@legalshield/adonis-ux-framework';

import { CartBuilderOfferData } from '../../types/globals';
import { PlansProps } from '../components/App/App';
import { cartBuilderData3, cartBuilderOfferData3 } from '../components/Test/CBData';
import { TestControl } from '../components/Test/Test';
import { useFeatureFlags } from '../hooks/feature-flags.hook';
import { CheckoutService } from '../services/checkout.service';
import { logger } from '../utils/logger';
import { CartBuilderItem } from '../view-models/cart-builder-item';
import { CrossSell } from './CrossSell/CrossSell';
import { Guideline } from './Guideline/Guideline';
import { AssociateType, isPlanValid, PlanConfiguration } from './PlanConfiguration/PlanConfiguration';

/**
 * An object that represents the user's selections during plan configuration.
 */
export type PlanConfigurationState = {
  associateType?: AssociateType;
  currentSupplements?: string[];
  currentTermName?: string;
  currentTierName?: string;
  offerId?: string;
  region?: string;
};

/**
 * Convenience object with methods that update PlanConfigurationState
 */
export interface PlanConfigurationSetters {
  setAssociateType: (associateType: AssociateType) => void;
  setCurrentSupplements: (supplements: string[]) => void;
  setCurrentTermName: (currentTermName: string) => void;
  setCurrentTierName: (currentTierName: string) => void;
  setOfferId: (offerId: string) => void;
  setRegion: (region: string) => void;
}

/**
 * A Map where the key is the index of the item in cartBuilderData.items,
 * and the value is `PlanConfigurationState`, an object that represents
 * the user's choices during plan configuration.
 */
export type OfferConfigurationMap = Map<number, PlanConfigurationState>;

export const AppRoutes = (props: PlansProps) => {
  const { multiselect } = useFeatureFlags();
  const { cartBuilderItemIndex, setCartBuilderItemIndex } = props;
  const [offerConfigurationMap, setOfferConfiguration] = useState<OfferConfigurationMap>(
    new Map(
      cartBuilderData.items.map((_item, index) => {
        const cartBuilderItem = new CartBuilderItem(index);
        return [index, cartBuilderItem.defaultPlanConfiguration()];
      }),
    ),
  );
  const planConfiguration = offerConfigurationMap.get(cartBuilderItemIndex);
  logger.group('AppRoutes');
  logger.log('cartBuilerItemIndex', cartBuilderItemIndex);
  logger.log('cartBuilderItem', cartBuilderData.items[cartBuilderItemIndex]);
  logger.log('planConfiguration', planConfiguration);
  logger.log('offerConfigurationMap.values', offerConfigurationMap.values());
  logger.log('offerConfigurationMap.entries', offerConfigurationMap.entries());
  logger.groupEnd();

  const setPlanConfiguration = useCallback(
    function (planConfig: PlanConfigurationState) {
      logger.log('in setPlanConfiguration', planConfig);
      if (planConfig.currentSupplements) {
        const hasBusinessPlus = planConfig.currentSupplements.includes('businessPlus');
        const hasHomeBusinessV2 = planConfig.currentSupplements.includes('homeBusiness_V2');

        const updateCartItems = (skip: boolean) => {
          if (cartBuilderData && cartBuilderData.items) {
            cartBuilderData.items = cartBuilderData.items.map((item) => {
              if (item.offers[0].offerId === planConfig.offerId) {
                const supplementIndex = item.offers[0].settings.supplements.findIndex(
                  (supplement) => supplement.name === 'businessPlus',
                );
                if (supplementIndex !== -1) {
                  item.offers[0].settings.supplements[supplementIndex].skip = skip;
                }
              }
              return item;
            });
          }
        };

        if (!hasBusinessPlus && !hasHomeBusinessV2) {
          updateCartItems(true);
        } else if (hasHomeBusinessV2) {
          if (!hasBusinessPlus) {
            updateCartItems(false);
          }
        } else if (hasBusinessPlus) {
          updateCartItems(false);
        }
      }

      setOfferConfiguration((prevOfferConfigurationMap) => {
        const prevPlanConfig = prevOfferConfigurationMap.get(cartBuilderItemIndex);
        return new Map(prevOfferConfigurationMap.set(cartBuilderItemIndex, { ...prevPlanConfig, ...planConfig }));
      });
    },
    [cartBuilderItemIndex],
  );

  useEffect(() => {
    const shouldReset = offerConfigurationMap.get(0)?.region === offerConfigurationMap.get(1)?.region;
    if (multiselect && cartBuilderItemIndex != 0) {
      const prevConfiguration: PlanConfigurationState = offerConfigurationMap.values().next().value;
      setPlanConfiguration({ ...planConfiguration, region: prevConfiguration.region });
    } else if (cartBuilderItemIndex === 0) {
      if (!shouldReset) {
        setOfferConfiguration(
          new Map(
            cartBuilderData.items.map((_item, index) => {
              const cartBuilderItem = new CartBuilderItem(index);
              if (index === 0) {
                return [index, { ...offerConfigurationMap.get(0) }];
              } else {
                return [
                  index,
                  {
                    ...cartBuilderItem.defaultPlanConfiguration(),
                  },
                ];
              }
            }),
          ),
        );
      }
    }
  }, [cartBuilderItemIndex, planConfiguration?.region]);

  const planConfigurationSetters = useMemo<PlanConfigurationSetters>(() => {
    return {
      setAssociateType(associateType) {
        logger.log('setting associateType', associateType);
        setPlanConfiguration({ ...planConfiguration, associateType });
      },
      setCurrentSupplements(currentSupplements) {
        logger.log('setting currentSupplements', currentSupplements);
        setPlanConfiguration({ ...planConfiguration, currentSupplements });
      },
      setCurrentTermName(currentTermName) {
        logger.log('setting currentTermName', currentTermName);
        setPlanConfiguration({ ...planConfiguration, currentTermName });
      },
      setCurrentTierName(currentTierName) {
        logger.log('setting currentTierName', currentTierName);
        setPlanConfiguration({ ...planConfiguration, currentTierName });
      },
      setOfferId(offerId) {
        logger.log('setting offerId', offerId);
        setPlanConfiguration({ ...planConfiguration, offerId });
      },
      setRegion(region) {
        logger.log('setting region', region);
        setPlanConfiguration({ ...planConfiguration, region });
      },
    };
  }, [planConfiguration, setPlanConfiguration]);

  function continueOrCheckout() {
    if (!multiselect) {
      checkout();
    }
    const nextItemIndex = cartBuilderItemIndex + 1;
    if (cartBuilderData.items[nextItemIndex]) {
      setCartBuilderItemIndex(nextItemIndex);
    } else {
      checkout();
    }
  }

  function goToPrevious() {
    const prevItemIndex = cartBuilderItemIndex - 1;
    if (prevItemIndex >= 0 && cartBuilderData.items[prevItemIndex]) {
      setCartBuilderItemIndex(prevItemIndex);
    }
  }

  async function checkout() {
    const filteredOfferConfigurationMap = new Map();
    offerConfigurationMap.forEach((value, key) => {
      const valid = isPlanValid(key, value.region || '');
      if (cartBuilderItemIndex > 0 && !valid) {
        return;
      }
      filteredOfferConfigurationMap.set(key, value);
    });
    await CheckoutService.checkout({ offerConfigurationMap: filteredOfferConfigurationMap });
  }

  if (!planConfiguration) {
    return <></>;
  }
  /**
   * Calculates the running total
   */
  const runningTotalPrice = [...(offerConfigurationMap || [])]
    .reduce((prevPrice, [itemIndex, planConfigurationState]) => {
      const prevConfiguration: PlanConfigurationState = offerConfigurationMap?.values()?.next()?.value;
      const {
        currentSupplements: supplements,
        currentTermName: termName,
        currentTierName: tierName,
        offerId,
      } = planConfigurationState;
      if (!tierName || !termName || !offerId || !supplements) {
        return prevPrice;
      }
      if (
        cartBuilderItemIndex !== 0 &&
        prevConfiguration.region !== '' &&
        !isPlanValid(itemIndex, prevConfiguration.region || '')
      ) {
        return prevPrice;
      }
      const cartBuilderItem = new CartBuilderItem(itemIndex);
      const totalPriceForItem = cartBuilderItem.totalPrice({ offerId, supplements, termName, tierName });
      return prevPrice + totalPriceForItem;
    }, 0)
    .toFixed(2);

  const runningItemCounts = [...(offerConfigurationMap || [])].reduce(
    (prevTotalItems, [itemIndex, planConfigurationState]) => {
      const prevConfiguration: PlanConfigurationState = offerConfigurationMap?.values()?.next()?.value;
      if (
        cartBuilderItemIndex !== 0 &&
        prevConfiguration.region !== '' &&
        !isPlanValid(itemIndex, prevConfiguration.region || '')
      ) {
        return prevTotalItems - 1;
      }
      const { currentSupplements: supplements } = planConfigurationState;
      if (!supplements) {
        return prevTotalItems;
      }
      const runningTotalItems = prevTotalItems + supplements.length;
      return runningTotalItems;
    },
    cartBuilderData.items.length,
  );

  return (
    <div>
      <Suspense fallback={<Spinner spinnerSize="large" />}>
        <Routes>
          <Route
            path="/"
            element={
              <PlanConfiguration
                cartBuilderData={cartBuilderData}
                cartBuilderItemIndex={cartBuilderItemIndex}
                planConfigurationSetters={planConfigurationSetters}
                cartBuilderOfferData={cartBuilderOfferData}
                continueOrCheckout={continueOrCheckout}
                key={'multiselect' + cartBuilderItemIndex}
                planConfiguration={planConfiguration}
                runningTotalPrice={runningTotalPrice}
                offerConfigurationMap={offerConfigurationMap}
                runningTotalItems={runningItemCounts}
              />
            }
          />
          <Route
            path="/ofh"
            element={
              <PlanConfiguration
                cartBuilderData={cartBuilderData3}
                cartBuilderOfferData={cartBuilderOfferData3 as CartBuilderOfferData}
                cartBuilderItemIndex={0}
                planConfigurationSetters={planConfigurationSetters}
                continueOrCheckout={() => {}}
                planConfiguration={planConfiguration}
                runningTotalPrice={runningTotalPrice}
                offerConfigurationMap={offerConfigurationMap}
                runningTotalItems={runningItemCounts}
              />
            }
          />
          <Route path="/identity-protection" element={<CrossSell />} />
          <Route path="/guideline" element={<Guideline />} />
          <Route
            path="/test"
            element={
              <TestControl
                offerIds={['a815ccf2-72f0-4e27-9a63-5ff00181c05b', 'a815ccf2-72f0-4e27-9a63-5ff00181c05c']}
              />
            }
          />
          <Route path="*" element={<div className="not-found">Page Not Found</div>} />
        </Routes>
      </Suspense>
    </div>
  );
};

import React, { useMemo } from 'react';
import { PlanCardAddOns } from '@legalshield/adonis-ux-framework';

import { CartBuilderProduct } from '../../../types/globals';
import themisAnalytics from '../..';
import { PlanConfigurationSetters } from '../../routes/AppRoutes';
import { downcaseKeys, find_term, find_tier, get_price, getPerTerm } from '../../utils';

interface SupplementsProps {
  cartBuilderItemIndex: number;
  planConfigurationSetters: PlanConfigurationSetters;
  currentSupplements: string[];
  currentTermName: string;
  currentTierName: string;
  offerId: string;
  region: string;
  setAndScrollToRegionError: () => void;
  onChangeDisabled: boolean;
  disabledType?: string;
  selectedAddOn?: string;
}

export const Supplements: React.FC<SupplementsProps> = ({
  cartBuilderItemIndex,
  planConfigurationSetters,
  currentSupplements,
  currentTermName,
  currentTierName,
  offerId,
  region,
  setAndScrollToRegionError,
  onChangeDisabled = false,
  disabledType,
  selectedAddOn,
}) => {
  const availableAddOns = useMemo(() => {
    const offer = cartBuilderOfferData[offerId];
    const productId = cartBuilderOfferData[offerId].products?.[0]?.id;
    const productVersion: number = cartBuilderOfferData[offerId].products?.[0]?.productVersion || 0;
    const simpleProductData: CartBuilderProduct =
      cartBuilderSimpleProductDataMap[productId + '|' + productVersion.toString()];

    if (!offer.products || offer.products.length != 1 || !offer.products[0].merchandizedTiers) return [];

    let tier = find_tier(offer.products[0].merchandizedTiers, currentTierName);

    if (tier === null) {
      tier = offer.products[0].merchandizedTiers[0];
    }

    if (!tier) return [];

    // Find the pricing term
    const price = find_term(tier?.prices || [], currentTermName);
    if (!price) return [];

    if (!price.supplements) {
      return [];
    }
    const supplements = downcaseKeys(simpleProductData?.supplements || {});
    let supplementsExcludingSkipped = price.supplements.filter(
      (supplement) =>
        !cartBuilderData.items[cartBuilderItemIndex].offers
          .find((itemOffer) => itemOffer.offerId === offer.id)
          ?.settings?.supplements?.filter((supplementSetting) => supplementSetting.skip === true)
          ?.map((supp) => supp.name?.toLowerCase())
          ?.includes(supplement.name?.toLowerCase() || ''),
    );

    /** Remove supplement for state MA */
    if ((tier?.name === 'Small Business Plus' || tier?.name === 'Small Business Pro') && region === 'MA') {
      supplementsExcludingSkipped = supplementsExcludingSkipped.filter(
        (supplement) => supplement.name !== 'trialDefenseBusiness',
      );
    }

    return supplementsExcludingSkipped.map((supplement) => ({
      name: supplements[supplement?.name?.toLowerCase() || ''],
      price: get_price(supplement?.initialListPrice || 0, currentTermName),
      value: supplement?.name || '',
    }));
  }, [cartBuilderOfferData, offerId, cartBuilderData.items, cartBuilderItemIndex, currentTermName, currentTierName]);

  /** Add or remove supplement */
  const onSupplementChange = (supplementName: string) => {
    if (!region) {
      return setAndScrollToRegionError();
    }
    if (!supplementName) {
      return;
    }
    const chosenSupplement = availableAddOns.find(
      (supplement) => supplement.value?.toLowerCase() === supplementName?.toLowerCase(),
    );
    let numberValue = 0;
    const value = chosenSupplement?.price || 0;

    if (typeof value === 'string') {
      numberValue = parseFloat(value.replace(/\$/g, ''));
    }

    let newSupplements: string[] = [...currentSupplements];
    if (supplementName === 'homeBusiness_V2' && newSupplements.includes('businessPlus')) {
      return;
    } else if (newSupplements.includes(supplementName)) {
      themisAnalytics.triggerRemoveFromCartEvent({
        currency: 'USD',
        items: [{ item_id: chosenSupplement?.value || supplementName, item_name: supplementName, price: numberValue }],
        value: numberValue,
      });
      newSupplements = currentSupplements.filter((currentSupplementName) => currentSupplementName != supplementName);
    } else {
      themisAnalytics.triggerAddToCartEvent({
        currency: 'USD',
        items: [{ item_id: chosenSupplement?.value || supplementName, item_name: supplementName, price: numberValue }],
        value: numberValue,
      });
      newSupplements.push(supplementName);
    }
    planConfigurationSetters.setCurrentSupplements(newSupplements);
  };

  return (
    ((availableAddOns?.length || 0) > 0 && (
      <div className={'coverage'}>
        <h2 className={'mt-4 mb-2 ml-4'}>{string_table?.COVERAGE_ADD_ONS}</h2>
        <PlanCardAddOns
          availableAddOns={availableAddOns || []}
          onAddOnSelect={onChangeDisabled ? () => {} : onSupplementChange}
          planRecurrence={`${getPerTerm(currentTermName)}`}
          selectedAddOns={[...currentSupplements]}
          data-pplsi-event-id={'plan-card-add-ons'}
          disabledType={disabledType}
          selectedAddOn={selectedAddOn}
        />
      </div>
    )) || <></>
  );
};

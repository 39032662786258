import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Alert,
  Container,
  Grid,
  GridRow,
  Heading,
  PlanCard,
  PlanCardRegionPicker,
  Text,
} from '@legalshield/adonis-ux-framework';
import { Offers } from '@legalshield/frontend-commons';

import './plan-configuration.scss';

import { per_term_strings } from '../../../strings/PeriodStrings';
import { CartBuilderData, CartBuilderOfferData } from '../../../types/globals';
import { BillingTerms } from '../../components/BillingTerms/BillingTerms';
import { CartBuilderFooter } from '../../components/CartBuilderFooter/CartBuilderFooter';
import { PlanInfoContainer } from '../../components/PlanInfoContainer/PlanInfoContainer';
import { Supplements } from '../../components/Supplements/Supplements';
import { Tiers } from '../../components/Tiers/Tiers';
import { useFeatureFlags } from '../../hooks/feature-flags.hook';
import { find_term, find_tier } from '../../utils';
import { logger } from '../../utils/logger';
import { CartBuilderItem, Prices } from '../../view-models/cart-builder-item';
import { OfferConfigurationMap, PlanConfigurationSetters, PlanConfigurationState } from '../AppRoutes';

export type AssociateType = 'Business' | 'Individual';

interface PlanConfigurationProps {
  cartBuilderData: CartBuilderData;
  cartBuilderItemIndex: number;
  cartBuilderOfferData: CartBuilderOfferData;
  continueOrCheckout: () => void;
  planConfiguration: PlanConfigurationState;
  planConfigurationSetters: PlanConfigurationSetters;
  runningTotalPrice: string;
  runningTotalItems: number;
  offerConfigurationMap: OfferConfigurationMap;
}

export const isPlanValid = (key: number, regionName: string) => {
  const allAvaiableRegions = new CartBuilderItem(key).availableRegions();
  const existedRegion = allAvaiableRegions.find((item) => item.value === regionName);
  if (!existedRegion?.value) {
    return false;
  } else {
    return true;
  }
};

const PlanConfigurationComponent = ({
  cartBuilderData,
  cartBuilderItemIndex,
  cartBuilderOfferData,
  continueOrCheckout,
  planConfiguration,
  planConfigurationSetters,
  runningTotalPrice,
  runningTotalItems,
  offerConfigurationMap,
}: PlanConfigurationProps) => {
  const { multiselect } = useFeatureFlags();
  const [regionErrorMessage, setRegionErrorMessage] = useState<string>('');
  const [showRegionPicker, setShowRegionPicker] = useState(true);
  const [showError, setShowError] = useState(false);
  const scrollToRef = useRef<HTMLDivElement | null>(null);

  /** Scrolls to region dropdown and sets error message */
  const setAndScrollToRegionError = () => {
    scrollToRef?.current?.scrollIntoView({ block: 'center' });
    return setRegionErrorMessage(string_table.REGION_ERROR);
  };

  const cartBuilderItem = useMemo(() => new CartBuilderItem(cartBuilderItemIndex), [cartBuilderItemIndex]);

  /** Pull dynamic data off of planConfiguration state */
  const region = planConfiguration.region || '';
  const currentTermName = planConfiguration.currentTermName || '';
  const currentTierName = planConfiguration?.currentTierName || '';
  const currentSupplements: PlanConfigurationState['currentSupplements'] = planConfiguration?.currentSupplements || [];
  const offerId = planConfiguration?.offerId || '';

  /** Update offerId when region changes */
  useEffect(() => {
    const newOfferId = cartBuilderItem.findOfferIdForRegion(region);

    if (newOfferId != planConfiguration?.offerId) {
      planConfigurationSetters.setOfferId(newOfferId);
    }
  }, [cartBuilderItem, planConfigurationSetters, planConfiguration?.offerId, region]);

  /** Sets current tier when offer changes */
  useEffect(() => {
    const offer = cartBuilderOfferData[offerId];
    const tier = find_tier(offer.products?.[0]?.merchandizedTiers || [], currentTierName);
    if (!tier) {
      planConfigurationSetters?.setCurrentTierName(offer.products?.[0]?.merchandizedTiers?.[0]?.name || '');
    }
  }, [cartBuilderOfferData, currentTierName, offerId]);

  /** sets currentTerm name when offerId or currentTierName changes, if necessary */
  useEffect(() => {
    const offer = cartBuilderOfferData[offerId];
    const tier = find_tier(offer.products?.[0]?.merchandizedTiers || [], currentTierName);
    if (!tier) return;

    // Preserve the billing period selected if exists in the new tier; otherwise use the default (first)
    const term = find_term(tier?.prices || [], currentTermName);
    if (!term) {
      planConfigurationSetters?.setCurrentTermName(tier.prices?.[0].billingPeriod || '');
    }
  }, [offerId, currentTierName, cartBuilderOfferData, currentTermName]);

  /** sets supplements when offer, tier, or term changes, if necessary */
  useEffect(() => {
    const offer = cartBuilderOfferData[offerId];
    const tier = find_tier(offer.products?.[0]?.merchandizedTiers || [], currentTierName);
    if (!tier) return;
    const term = find_term(tier?.prices || [], currentTermName);
    if (!term) return;

    // Preserve the supplements if exists in the new price term
    let newSupplements = [...currentSupplements];

    for (let i = 0; i < currentSupplements.length; i++) {
      // if none of the supplements of the term are the current supplements
      // then remove current supplements
      if (
        !term?.supplements?.some(
          (item: Offers.MerchandizedSupplement) => item?.name?.toLowerCase() === currentSupplements[i].toLowerCase(),
        )
      ) {
        newSupplements = newSupplements.filter((value) => value.toLowerCase() != currentSupplements[i].toLowerCase());
        planConfigurationSetters.setCurrentSupplements(newSupplements);
      }
    }
  }, [offerId, currentTierName, currentTermName, cartBuilderOfferData, currentSupplements]);

  const [disableContinueButton, setDisableContinueButton] = useState(false);

  /** When region changes, calculate disable button continue */
  useEffect(() => {
    if ((region as string) === '') {
      setDisableContinueButton(true);
    } else {
      if (
        cartBuilderItem.productFamilyForOfferId(offerId) === 'Legal' &&
        (invalidRegionData || []).map((item) => item?.abbreviation)?.includes(region)
      ) {
        setDisableContinueButton(true);
      } else {
        setDisableContinueButton(false);
      }
    }
  }, [cartBuilderItem, offerId, region]);

  const onRegionChange = (value: string): void => {
    if (value === region) {
      return;
    }

    planConfigurationSetters.setRegion(value);
    setRegionErrorMessage('');
  };

  const addPriceWithString = (price1: string, price2: string) => {
    return `$${(+price1?.slice(1) + +price2?.slice(1)).toFixed(2)}`;
  };

  const prices: Prices = useMemo(() => {
    const totalPrices: Prices = {};
    const allOfferConfigurationMap = [...offerConfigurationMap];
    for (let i = 0; i <= offerConfigurationMap.size - 1; i++) {
      const [key, value] = allOfferConfigurationMap[i];
      const item = new CartBuilderItem(i).extractPrices({
        offerId: value.offerId || '',
        supplements: value.currentSupplements || [],
        termName: value?.currentTermName || '',
        tierName: value?.currentTierName || '',
      });
      const valid = isPlanValid(i, region || '');
      if (cartBuilderItemIndex === 0 || (cartBuilderItemIndex > 0 && valid)) {
        totalPrices.planFees = addPriceWithString(totalPrices?.planFees || '', item?.planFees || '');
        totalPrices.planPrice =
          item.planFees !== '$0.00' && item.planPrice === '$0.00'
            ? totalPrices.planPrice
            : addPriceWithString(totalPrices?.planPrice || '', item?.planPrice || '');
        totalPrices.totalPrice = addPriceWithString(totalPrices?.totalPrice || '', item?.totalPrice || '');
        totalPrices.titlePrice = key === cartBuilderItemIndex ? item.titlePrice : totalPrices.titlePrice;
      } else if (cartBuilderItemIndex > 0 && !valid) {
        totalPrices.titlePrice = key === cartBuilderItemIndex ? item.titlePrice : totalPrices.titlePrice;
      }
    }
    return totalPrices;
  }, [offerConfigurationMap, cartBuilderItemIndex, region]);

  const next = () => {
    if (!region) {
      setAndScrollToRegionError();
      return;
    }
    if (disableContinueButton) {
      return;
    }
    window.scrollTo(0, 0);
    continueOrCheckout();
  };

  useEffect(() => {
    if (multiselect && cartBuilderItemIndex != 0 && !!region && showRegionPicker) {
      setShowRegionPicker(false);
      if (cartBuilderItemIndex > 0 && !isPlanValid(cartBuilderItemIndex, region || '')) {
        setShowError(true);
      }
    }
  }, [region]);

  return (
    <div className="plan-configuration">
      <Grid>
        <GridRow variant="center">
          <Container
            alignItems="center"
            background="light-gray"
            classNames={['m-4', 'sub-container']}
            justifyContent="center"
          >
            <Heading as="T39" text={string_table?.CONFIGURE_YOUR_COVERGAE} classNames={['title']} />
            <Text text={string_table?.COVERAGE_DESCRITION} textSize="large" classNames={['title-description']} />
            <div className="checkbox-section mb-8">
              <PlanCard isSelected={!showError} selectable>
                <PlanInfoContainer
                  cartBuilderItem={cartBuilderItem}
                  currentSupplements={currentSupplements}
                  currentTermName={currentTermName}
                  currentTierName={currentTierName}
                  offerId={offerId}
                  planPrice={prices.titlePrice}
                  region={region}
                  seeDetailText={string_table.SEE_DETAILS}
                >
                  {showRegionPicker && (
                    <PlanCardRegionPicker
                      availableRegions={cartBuilderItem.availableRegions()}
                      invalidRegionTitle={string_table.INVALID_REGION_TITLE}
                      invalidRegionMessage={string_table.INVALID_REGION_MESSAGE}
                      invalidRegions={
                        cartBuilderItem.productFamilyForOfferId(offerId) === 'Legal'
                          ? invalidRegionData?.map((regions) => regions?.abbreviation)
                          : []
                      }
                      selectedRegion={region}
                      onRegionSelect={onRegionChange}
                      placeholder={string_table_market.SELECT_YOUR_STATE}
                      classNames={[!!regionErrorMessage && 'error-box']}
                      innerRef={scrollToRef}
                      data-pplsi-event-id={'plan-card-region'}
                    />
                  )}
                  {showError && (
                    <Alert
                      severity="warning"
                      title={string_table.INVALID_REGION_TITLE}
                      classNames={[`lsux-plan-card__region-selector__alert`, 'mt-4']}
                    >
                      {string_table.INVALID_REGION_MESSAGE}
                    </Alert>
                  )}
                  {!!regionErrorMessage && <Text classNames={['validate-error']}>{regionErrorMessage}</Text>}
                  <Tiers
                    planConfigurationSetters={planConfigurationSetters}
                    currentTermName={currentTermName}
                    currentTierName={currentTierName}
                    offerId={offerId}
                    region={region}
                    setAndScrollToRegionError={setAndScrollToRegionError}
                    onChangeDisabled={showError}
                    periodTerm={per_term_strings['MONTHLY'][pplsi.market?.toLowerCase()]}
                  />

                  <BillingTerms
                    currentTermName={currentTermName}
                    currentTierName={currentTierName}
                    planConfigurationSetters={planConfigurationSetters}
                    offerId={offerId}
                    region={region}
                    setAndScrollToRegionError={setAndScrollToRegionError}
                  />
                </PlanInfoContainer>

                <Supplements
                  cartBuilderItemIndex={cartBuilderItemIndex}
                  planConfigurationSetters={planConfigurationSetters}
                  currentSupplements={currentSupplements}
                  currentTermName={currentTermName}
                  currentTierName={currentTierName}
                  offerId={offerId}
                  region={region}
                  setAndScrollToRegionError={setAndScrollToRegionError}
                  onChangeDisabled={showError}
                  disabledType={'homeBusiness_V2'}
                  selectedAddOn={'businessPlus'}
                />
              </PlanCard>
            </div>
          </Container>
        </GridRow>
      </Grid>
      <CartBuilderFooter
        currentTermName={currentTermName}
        disableContinueButton={disableContinueButton}
        next={next}
        prices={prices}
        runningTotalPrice={runningTotalPrice}
        runningTotalItems={runningTotalItems}
        selectedCardCount={offerConfigurationMap.size || 1}
      />
    </div>
  );
};

export const PlanConfiguration = PlanConfigurationComponent;

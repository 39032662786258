import React from 'react';
import { Button, Container, Divider, Grid, GridCol, GridRow, Text } from '@legalshield/adonis-ux-framework';

import { get_term } from '../../utils';
import { Prices } from '../../view-models/cart-builder-item';

export function CartBuilderFooter({
  currentTermName,
  disableContinueButton,
  next,
  prices,
  runningTotalPrice,
  runningTotalItems,
  selectedCardCount,
}: {
  currentTermName: string;
  disableContinueButton: boolean;
  next: () => void;
  prices: Prices;
  runningTotalPrice: string;
  runningTotalItems: number;
  selectedCardCount: number;
}) {
  return (
    <Grid classNames={['total-section']}>
      <GridRow variant="center">
        <Container
          alignItems="center"
          classNames={['mt-4 mb-4 ml-4', 'total-section-container']}
          justifyContent="center"
        >
          <Grid>
            <GridRow variant="halves-fixed" classNames={['price-section']}>
              <GridCol>
                <Text text={get_term(currentTermName)} textSize="large" />
                {prices?.planFees !== '$0.00' ? (
                  <>
                    <br />
                    <Text text={string_table.ONE_TIME_FEE_CAPS} textSize="large" />
                  </>
                ) : (
                  <></>
                )}
              </GridCol>
              <GridCol>
                <Text
                  text={prices?.planPrice || '$0.00'}
                  textSize="large"
                  classNames={['price-total-section']}
                  data-pplsi-event-id={'total-container-monthly-price'}
                />
                {prices?.planFees !== '$0.00' ? (
                  <>
                    <br />
                    <Text
                      text={`${prices?.planFees}`}
                      textSize="large"
                      classNames={['price-total-section']}
                      data-pplsi-event-id={'total-container-fees-price'}
                    />
                  </>
                ) : (
                  <></>
                )}
              </GridCol>
              <GridCol>
                <Text
                  text={`${string_table?.TOTAL} ${`(${runningTotalItems + ' ' + string_table?.ITEMS}${
                    runningTotalItems > 1 ? 's' : ''
                  })`}`}
                  textWeight="bold"
                  textSize="large"
                  style={{ width: '380px' }}
                />
              </GridCol>
              <GridCol>
                <Text
                  text={`$${runningTotalPrice}`}
                  textWeight="bold"
                  textSize="large"
                  classNames={['price-total-section']}
                  data-pplsi-event-id={'total-container-total-price'}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <Button
                classNames={['mb-6', 'button-next']}
                label={string_table?.CONTINUE}
                stretch
                variant="primary"
                onClick={() => next()}
                disabled={disableContinueButton}
                pplsiEventId={'continue-button'}
              />
            </GridRow>
          </Grid>
        </Container>
      </GridRow>
    </Grid>
  );
}

import {
  Brand,
  createD2COrderV2LowVerbosity,
  createWALSOrderV2MidVerbosity,
  Environment,
  OrderServiceV2,
  OrderVersion,
  RedirectService,
  USStates,
} from '@legalshield/commerce-cart-library';
import { Purchases } from '@legalshield/frontend-commons';

import themisAnalytics from '../';
import { OfferConfigurationMap } from '../routes/AppRoutes';
import { handleError } from '../utils/error.util';
import { CartBuilderItem } from '../view-models/cart-builder-item';

export const transformProductToECommerceItem = (createdOrder: Purchases.OrderResource) => {
  createdOrder.offerSelections?.flatMap((offerSelection) =>
    offerSelection.products.map((product) => ({
      brand: product.productType || '',
      category: product.productFamily || '',
      item_id: product.productId || '',
      item_name: product.tierName || '', // if productFamily is null, default to empty string
      price: product.productPrice || 0, // if productPrice is null, default to 0
      quantity: 1, // if quantity is null, default to 1
    })),
  );
};

export const transformOfferSelectionToECommerceItems = (offerSelection: Purchases.Offer2[] | null | undefined) => {
  if (!offerSelection) {
    return [];
  }

  return offerSelection.flatMap((offer) => offer.products.map(transformProductToECommerceItem));
};

const onBeginCheckout = (createdOrder: Purchases.OrderResource) => {
  const eventItems = createdOrder.offerSelections?.flatMap((offerSelection) =>
    offerSelection.products?.map((product) => ({
      item_id: product.productId || '',
      item_name: product?.tierName || '',
      price: product?.productPrice || 0,
      quantity: 1,
    })),
  );

  if (!eventItems) {
    return;
  }

  themisAnalytics.triggerBeginCheckoutEvent({
    currency: 'USD',
    items: eventItems,
    value: createdOrder.totalPrice || 0,
  });
};

/**
 * Creates an order that includes all of the configured offers.
 * Maps the `offerConfigurationMap` into `offerSelections`, and using
 * the commerce-cart-library to create an order with them.
 */
const checkout = async ({ offerConfigurationMap }: { offerConfigurationMap: OfferConfigurationMap }) => {
  try {
    const offerSelections: Purchases.Offer2[] = [];

    /**
     * `orderTotalPrice`, `orderTermName`, `orderTermName`, `orderBrand`, and `orderRegion`
     * are calculated for the entire order, not just one `offerSelection`
     **/
    let orderTotalPrice = 0;
    let orderTermName = '';
    let orderBrand: Brand | null = null;
    let orderRegion = '';

    [...offerConfigurationMap].forEach(([cartBuilderItemIndex, planConfiguration]) => {
      const {
        offerId,
        currentTermName: termName,
        currentTierName: tierName,
        currentSupplements: supplements,
        region,
      } = planConfiguration;
      const cartBuilderItem = new CartBuilderItem(cartBuilderItemIndex);
      offerSelections.push(cartBuilderItem.buildOfferSelection(planConfiguration));

      if (!tierName || !termName || !offerId || !supplements) {
        return '';
      }
      orderTermName ||= termName;
      orderBrand ||=
        cartBuilderItem.simpleProductDataForOfferId(offerId)?.productFamily === 'Identity'
          ? Brand.IDSHIELD
          : Brand.LEGALSHIELD;
      orderRegion ||= region || '';
      const prices = cartBuilderItem.extractPrices({ offerId, supplements, termName, tierName });
      orderTotalPrice += Number(prices.totalPrice?.slice(1));
    });

    const requestPayload = {
      billingFrequency: {
        period: orderTermName as Purchases.BillingPeriod,
        term: 1,
      },
      currency: Purchases.Currency.USD,
      marketLocale: pplsi.market,
      marketingSite: undefined,
      offerSelections,
      totalPrice: orderTotalPrice,
    };

    const WALSOrderInfo: Purchases.OrderRequest2 = !associateData
      ? createD2COrderV2LowVerbosity(requestPayload)
      : createWALSOrderV2MidVerbosity({
          ...requestPayload,
          marketingSite: 'BUYNOW',
          sponsorId: associateData.id,
        });

    if (associateData) {
      WALSOrderInfo.source = {
        ...WALSOrderInfo.source,
        marketingChannel: 'BUYNOW',
        sponsorFriendlyId: associateData.friendlyId,
        sponsorId: associateData.id,
        sponsorSiteName: associateData.sitename,
      } as Purchases.Source2;
    }

    const redirectService = new RedirectService();
    const orderServiceV2 = new OrderServiceV2();
    const walsOrder = await orderServiceV2.anonymousCreateOrderV2({
      environmentArg: getEnvironment(),
      orderInfo: WALSOrderInfo,
    });

    onBeginCheckout(walsOrder);

    redirectService.redirectToCheckout({
      brand: orderBrand || Brand.LEGALSHIELD,
      environment: getEnvironment(),
      hostName: associateData?.sitename
        ? associateData.sitename + '.' + pplsi?.envPrefix + 'legalshieldassociate.com'
        : '',
      marketLocale: pplsi.market,
      orderID: walsOrder?.id?.toString() || '',
      orderVersion: OrderVersion.ORDERV2OFFERS,
      region: orderRegion as USStates,
      source: 'https://cart.' + pplsi.envPrefix + 'legalshield.com',
    });
  } catch (err) {
    handleError(err, {
      context: 'Order Creation',
    });
  }
};

const getEnvironment = () => {
  switch (pplsi.envPrefix) {
    case 'uat-':
      return Environment.UAT;
    case 'dev-':
      return Environment.DEVELOPMENT;
    default:
      return Environment.PRODUCTION;
  }
};

export const CheckoutService = {
  checkout,
};

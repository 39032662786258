import { SelectOption } from '@legalshield/adonis-ux-framework';
import { createOfferSelection, createProductV2, createSupplement2 } from '@legalshield/commerce-cart-library';
import { Offers, Purchases } from '@legalshield/frontend-commons';

import { CartBuilderData, CartBuilderProduct } from '../../types/globals';
import { PlanConfigurationState } from '../routes/AppRoutes';
import { downcaseKeys, find_term, find_tier, get_price } from '../utils';

interface AddOnOption {
  name: string;
  price: string;
  value: string;
}
interface TierRadio {
  name: string;
  price: number;
  tierName: string;
}

export interface Prices {
  planPrice?: string;
  planFees?: string;
  titlePrice?: number;
  totalPrice?: string;
}
export class CartBuilderItem {
  private _availableAddOns?: AddOnOption[];
  private _availableRegions?: SelectOption[];
  private _availableTiers?: TierRadio[];
  private _cartBuilderItem: CartBuilderData['items'][number];
  private _prices?: Prices;

  constructor(cartBuilderItemIndex: number) {
    this._cartBuilderItem = cartBuilderData.items[cartBuilderItemIndex];
    if (!this._cartBuilderItem) {
      throw new Error(`no CartBuilderItem for given index: ${cartBuilderItemIndex}`);
    }
  }

  availableAddOns({
    currentTermName,
    currentTierName,
    offerId,
  }: {
    currentTermName: string;
    currentTierName: string;
    offerId: string;
  }) {
    return this.findAvailableAddOns({ currentTermName, currentTierName, offerId });
  }
  /** Select options for the region picker */
  availableRegions(): SelectOption[] {
    return this.findAvailableRegions();
  }

  /** Select options for the Tier radio group */
  availableTiersForOfferAndTerm(offerId: string, currentTermName: string): TierRadio[] {
    return this.findAvailableTiers(offerId, currentTermName);
  }

  /** Build an offerSelection object for the create order api request */
  buildOfferSelection(planConfiguration: PlanConfigurationState): Purchases.Offer2 {
    const { currentSupplements, currentTermName, currentTierName, offerId } = planConfiguration;
    if (!offerId) {
      throw new Error('offerId is required');
    }
    if (!currentTermName) {
      throw new Error('currentTermName is required');
    }
    if (!currentTierName) {
      throw new Error('currentTierName is required');
    }
    const offerData = cartBuilderOfferData[offerId];

    const selectedSupplements: Purchases.Supplement2[] | undefined = this.availableAddOns({
      currentTermName,
      currentTierName,
      offerId,
    })
      ?.filter((addon) => currentSupplements?.includes(addon?.value || ''))
      .map((addon) => createSupplement2({ name: addon?.value || '', price: Number(addon?.price?.slice(1)) || 0 }));

    const defaultProduct = offerData?.products?.[0];
    const selectedTier = find_tier(defaultProduct?.merchandizedTiers || [], currentTierName);
    const selectedPrice = find_term(selectedTier?.prices || [], currentTermName);

    const product = createProductV2({
      discountIds: [],
      prepaidTerm: 1,
      productId: offerData?.products?.[0]?.id || '',
      productPrice: selectedPrice?.initialListPrice,
      supplementSelections: selectedSupplements,
      tierName: currentTierName,
    });

    const associateType = this._cartBuilderItem.offers.find((offer) => offer.offerId === offerId)?.selections
      ?.associateType;
    if (associateType) {
      Object.assign(product, { productInfo: { ...product.productInfo, associateType } });
    }

    product.productFamily = defaultProduct?.productFamily;
    product.productType = defaultProduct?.productType;
    product.productVersion = defaultProduct?.productVersion;
    if (selectedTier?.fees) {
      product.fees = selectedTier.fees;
    }

    const offerSelection = createOfferSelection({
      offerId,
      offerProducts: [product],
    });
    return offerSelection;
  }

  defaultOffer() {
    return cartBuilderOfferData[this.defaultOfferId()];
  }

  defaultOfferId(): string {
    return this.findOfferIdForRegion(this.availableRegions()[0]?.value?.toString());
  }

  defaultPlanConfiguration(): PlanConfigurationState {
    return {
      currentSupplements: this.defaultSupplements(),
      currentTermName: this.defaultTerm(),
      currentTierName: this.defaultTier(),
      offerId: this.defaultOfferId(),
      region: this.defaultRegion(),
    };
  }

  defaultRegion(): string {
    return '';
  }

  defaultSupplements(): string[] {
    return [];
  }

  defaultTerm(): string {
    const monthlyTerm = this.defaultOffer()?.products?.[0].merchandizedTiers?.[0].prices?.find(
      (price) => price.billingPeriod === Offers.BillingPeriod.MONTHLY,
    )?.billingPeriod;
    return monthlyTerm || this.defaultOffer()?.products?.[0].merchandizedTiers?.[0].prices?.[0].billingPeriod || '';
  }

  defaultTier(): string {
    return this.defaultOffer()?.products?.[0]?.merchandizedTiers?.[0]?.name || '';
  }

  extractPrices({
    offerId,
    supplements,
    termName,
    tierName,
  }: {
    offerId: string;
    tierName: string;
    termName: string;
    supplements: string[];
  }) {
    return this.findPrices({ offerId, supplements, termName, tierName });
  }

  findOfferIdForRegion(region: string): string {
    const offerIds: string[] = this._cartBuilderItem.offers?.map((itemOfferConfig) => itemOfferConfig.offerId);
    if (offerIds.length === 1) {
      return offerIds[0];
    } else {
      for (let i = 0; i < offerIds.length; i++) {
        const offer = cartBuilderOfferData[offerIds[i]];
        for (let j = 0; j < offer.availableLocalities.length; j++) {
          if (region === offer.availableLocalities[j].abbreviation) {
            return offerIds[i];
          }
        }
      }
    }

    if (!offerIds[0]) {
      throw new Error(`no offer found for region ${region}`);
    }

    return offerIds[0];
  }

  productTypeForOfferId(offerId: string) {
    return this.simpleProductDataForOfferId(offerId)?.productType;
  }

  productFamilyForOfferId(offerId: string): string {
    return cartBuilderOfferData[offerId]?.products?.[0]?.productFamily || '';
  }

  simpleProductDataForOfferId(offerId: string): CartBuilderProduct | undefined {
    const offer = cartBuilderOfferData[offerId];
    const productId = offer?.products?.[0]?.id;
    const productVersion = cartBuilderOfferData[offerId]?.products?.[0]?.productVersion || '';
    const simpleProductData = cartBuilderSimpleProductDataMap[productId + '|' + productVersion.toString()];
    return simpleProductData;
  }

  private findAvailableAddOns({
    currentTermName,
    currentTierName,
    offerId,
  }: {
    currentTermName: string;
    currentTierName: string;
    offerId: string;
  }) {
    const offer = cartBuilderOfferData[offerId];
    const productId = cartBuilderOfferData[offerId].products?.[0]?.id;
    const productVersion: number = cartBuilderOfferData[offerId].products?.[0]?.productVersion || 0;
    const simpleProductData: CartBuilderProduct =
      cartBuilderSimpleProductDataMap[productId + '|' + productVersion.toString()];

    if (!offer.products || offer.products.length != 1 || !offer.products[0].merchandizedTiers) return [];

    let tier = find_tier(offer.products[0].merchandizedTiers, currentTierName);

    if (tier === null) {
      tier = offer.products[0].merchandizedTiers[0];
    }

    if (!tier) return [];

    // Find the pricing term
    const price = find_term(tier?.prices || [], currentTermName);
    if (!price) return [];

    if (!price.supplements) {
      return [];
    }
    const supplements = downcaseKeys(simpleProductData?.supplements || {});
    const supplementsExcludingSkipped = price.supplements.filter(
      (supplement) =>
        !this._cartBuilderItem.offers
          .find((itemOffer) => itemOffer.offerId === offer.id)
          ?.settings?.supplements?.filter((supplementSetting) => supplementSetting.skip === true)
          ?.map((supp) => supp.name?.toLowerCase())
          ?.includes(supplement.name?.toLowerCase() || ''),
    );
    return supplementsExcludingSkipped.map((supplement) => ({
      name: supplements[supplement?.name?.toLowerCase() || ''],
      price: get_price(supplement?.initialListPrice || 0, currentTermName),
      value: supplement?.name || '',
    }));
  }

  private findAvailableRegions(): SelectOption[] {
    const offerRegions: string[] = [];
    let options: SelectOption[] = [];
    const offerIds = this._cartBuilderItem.offers?.map((offer) => offer?.offerId);

    for (let i = 0; i < (offerIds.length || 0); i++) {
      const offerId = offerIds[i];
      const offer = cartBuilderOfferData[offerId];

      for (let j = 0; j < offer.availableLocalities.length; j++) {
        if (!(offer.availableLocalities[j].abbreviation in offerRegions)) {
          offerRegions.push(offer.availableLocalities[j].abbreviation);
          options.push({ label: offer.availableLocalities[j].name, value: offer.availableLocalities[j].abbreviation });
        }
      }
    }

    // Use only the regions the user is licensed to sell in (for legal products)
    if (regionData) {
      options = [];
      for (let i = 0; i < regionData.length; i++) {
        if (offerRegions.includes(regionData[i].abbreviation)) {
          options.push({ label: regionData[i].fullName, value: regionData[i].abbreviation });
        }
      }
    }

    // Allow in the invalid regions if not legal
    if (invalidRegionData) {
      for (let i = 0; i < invalidRegionData.length; i++) {
        if (offerRegions.includes(invalidRegionData[i].abbreviation)) {
          options.push({ label: invalidRegionData[i].fullName, value: invalidRegionData[i].abbreviation });
        }
      }
    }

    options.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
    this._availableRegions = options;
    return options;
  }

  /**
   * Returns the total price of the item, given current selections.
   */
  totalPrice({
    offerId,
    supplements,
    termName,
    tierName,
  }: {
    offerId: string;
    tierName: string;
    termName: string;
    supplements: string[];
  }): number {
    const prices = this.extractPrices({ offerId, supplements, termName, tierName });

    return Number(prices.totalPrice?.slice(1));
  }

  private findAvailableTiers(offerId: string, currentTermName: string) {
    const tiers = cartBuilderOfferData?.[offerId]?.products?.[0].merchandizedTiers || [];

    const productId = cartBuilderOfferData?.[offerId]?.products?.[0].id;
    const productVersion = cartBuilderOfferData?.[offerId]?.products?.[0]?.productVersion;
    const simpleProductData = cartBuilderSimpleProductDataMap[productId + '|' + productVersion?.toString()];

    if (!simpleProductData || !simpleProductData.tiers) return [];
    const options: TierRadio[] = [];
    for (let i = 0; i < tiers?.length; i++) {
      const tierNameFromCBOfferData = tiers?.[i]?.name || '';
      const tierNameFromSimpleProductData = simpleProductData.tiers[tierNameFromCBOfferData] || tierNameFromCBOfferData;
      let priceString = 0;
      if (currentTermName) {
        for (let j = 0; j < (tiers?.[i]?.prices?.length || 0); j++) {
          if (tiers?.[i]?.prices?.[j]?.billingPeriod === currentTermName) {
            priceString = tiers?.[i]?.prices?.[j]?.initialListPrice || 0;
            options.push({
              name: tierNameFromSimpleProductData,
              price: priceString,
              tierName: tierNameFromCBOfferData,
            });
          }
        }
      }
    }
    this._availableTiers = options;
    return options;
  }

  private findPrices({
    offerId,
    supplements,
    termName,
    tierName,
  }: {
    offerId: string;
    tierName: string;
    termName: string;
    supplements: string[];
  }): Prices {
    const offer = cartBuilderOfferData[offerId];

    if (!offer.products || offer.products.length != 1 || !offer.products[0].merchandizedTiers) return {};

    let tier = find_tier(offer.products[0].merchandizedTiers, tierName);

    if (tier === null) {
      tier = offer.products[0].merchandizedTiers[0];
    }

    if (!tier) return {};

    // Find the price and the term
    let price = 0;
    let offerPrice: Offers.Price | null = null;
    for (let i = 0; i < (tier?.prices?.length || 0); i++) {
      if (termName.toLowerCase() === tier?.prices?.[i]?.billingPeriod?.toLowerCase()) {
        offerPrice = tier.prices?.[i];
        price = tier.prices?.[i]?.initialListPrice || 0;
        break;
      }
    }

    if (offerPrice === null) {
      return {};
    }

    // Calcluate any fees
    let fees = 0;
    if (tier.fees) {
      for (const key in tier.fees) {
        fees = fees + tier.fees[key];
      }
    }
    const planCardPrice = price;
    for (let i = 0; i < (offerPrice?.supplements?.length || 0); i++) {
      const supplement: Offers.MerchandizedSupplement | undefined = offerPrice?.supplements?.[i];

      if (supplements?.includes(supplement?.name || '')) {
        price = price + (supplement?.initialListPrice || 0);
      }
    }
    const getPrice = get_price(price, termName);
    const getFees = get_price(fees, 'ONCE');
    const totalPrice = get_price(price + fees, termName);
    const titlePrice = parseFloat((planCardPrice === 0 ? fees : planCardPrice)?.toFixed(2));

    const prices = {
      planFees: getFees,
      planPrice: getPrice,
      titlePrice,
      totalPrice,
    };

    this._prices = prices;
    return prices;
  }
}

import { Offers } from '@legalshield/frontend-commons';

import { dd_term_strings, per_term_strings } from '../../strings/PeriodStrings';

/** Return a matching Offer's Price, given a termName */
export const find_term = (prices: Offers.Price[], termName: string): Offers.Price | undefined => {
  if (termName === '') return prices[0];

  for (let i = 0; i < prices.length; i++) {
    if (prices?.[i]?.billingPeriod?.toLowerCase() === termName.toLowerCase()) {
      return prices[i];
    }
  }
  return undefined;
};

export const find_tier = (
  merchandizedTiers: Offers.MerchandizedTier[],
  tierName: string,
): Offers.MerchandizedTier | undefined => {
  if (tierName === '') return merchandizedTiers[0];

  // Find the tier from the name
  for (let i = 0; i < merchandizedTiers.length; i++) {
    if (merchandizedTiers?.[i]?.name?.toLowerCase() === tierName?.toLowerCase()) {
      return merchandizedTiers[i];
    }
  }

  return undefined;
};

export const getPerTerm = (term: string): string => {
  if (!term) return '';
  return per_term_strings[term][pplsi.market?.toLowerCase()];
};

export const get_price = (price: number, term: string): string => {
  if (!term) return '';
  return '$' + price.toFixed(2);
};

export const get_term = (term: string): string => {
  if (!term) return '';
  return dd_term_strings[term][pplsi.market?.toLowerCase()];
};

export const downcaseKeys = <T>(obj: Record<string, T>): Record<string, T> =>
  Object.keys(obj)?.reduce((acc: Record<string, T>, k: string) => {
    acc[k?.toLowerCase()] = obj[k];
    return acc;
  }, {});

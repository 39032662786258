import React, { forwardRef, useEffect, useState } from 'react';
import { Checkbox, Select, SelectItem, SelectOption } from '@legalshield/adonis-ux-framework';

import { dd_term_strings, per_term_strings } from '../../../strings/PeriodStrings';
import { CartBuilderProduct, OfferPrice, OfferSupplement, OfferTier } from '../../../types/globals';
import { cartBuilderOfferData3 } from './CBData';

export interface TestControlProps extends Omit<React.HTMLProps<HTMLDivElement>, 'onClick' | 'css'> {
  offerIds: string[];
}
const TestControlComponent = ({ offerIds }: TestControlProps) => {
  const get_possible_regions = (): SelectOption[] => {
    const offerRegions: string[] = [];

    let options: SelectOption[] = [];

    for (let i = 0; i < offerIds.length; i++) {
      const offerId = offerIds[i];
      const offer = cartBuilderOfferData3[offerId];

      if (offer.products[0].productFamily === 'Identity' || offer.products[0].productFamily === 'Associate') {
        return options;
      }

      for (let j = 0; j < offer.availableLocalities.length; j++) {
        if (!(offer.availableLocalities[j].abbreviation in offerRegions)) {
          offerRegions.push(offer.availableLocalities[j].abbreviation);
          options.push({ label: offer.availableLocalities[j].name, value: offer.availableLocalities[j].abbreviation });
        }
      }
    }

    // Use only the regions the user is licensed to sell in (for legal products)
    if (regionData) {
      options = [];
      for (let i = 0; i < regionData.length; i++) {
        if (offerRegions.includes(regionData[i].abbreviation)) {
          options.push({ label: regionData[i].fullName, value: regionData[i].abbreviation });
        }
      }
    }

    // Allow in the invalid regions if not legal
    if (invalidRegionData) {
      for (let i = 0; i < invalidRegionData.length; i++) {
        if (offerRegions.includes(invalidRegionData[i].abbreviation)) {
          options.push({ label: invalidRegionData[i].fullName, value: invalidRegionData[i].abbreviation });
        }
      }
    }

    options.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

    return options;
  };

  const find_offer_id = (region: string): string => {
    if (offerIds.length === 1) {
      return offerIds[0];
    } else {
      for (let i = 0; i < offerIds.length; i++) {
        const offer = cartBuilderOfferData3[offerIds[i]];
        for (let j = 0; j < offer.availableLocalities.length; j++) {
          if (region === offer.availableLocalities[j].abbreviation) {
            return offerIds[i];
            break;
          }
        }
      }
    }

    return offerIds[0];
  };

  const find_tier = (merchandizedTiers: OfferTier[], tierName: string): OfferTier | undefined => {
    if (tierName === '') return merchandizedTiers[0];

    // Find the tier from the name
    for (let i = 0; i < merchandizedTiers.length; i++) {
      if (merchandizedTiers[i].name.toLowerCase() === tierName.toLowerCase()) {
        return merchandizedTiers[i];
      }
    }

    return undefined;
  };

  // const onTierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const find_term = (prices: OfferPrice[], termName: string): OfferPrice | undefined => {
    if (termName === '') return prices[0];

    for (let i = 0; i < prices.length; i++) {
      if (prices[i].billingPeriod.toLowerCase() === termName.toLowerCase()) {
        return prices[i];
      }
    }
    return undefined;
  };

  const [possibleRegions, setPossibleRegions] = useState(get_possible_regions());

  const iRegion = possibleRegions[0].value as string;
  const iOfferId = find_offer_id(possibleRegions[0].value as string);
  const iOffer = cartBuilderOfferData3[iOfferId];

  const [region, setRegion] = useState(iRegion);

  // When the offer is set
  const [offerId, setOfferId] = useState(iOfferId);

  // When the tier name is set to a value
  const [currentTierName, setCurrentTierName] = useState(iOffer.products[0].merchandizedTiers[0].name);

  // When the term name is set to a value
  const [currentTermName, setCurrentTermName] = useState(
    iOffer.products[0].merchandizedTiers[0].prices[0].billingPeriod,
  );

  // When supplements are selected
  const [currentSupplements, setCurrentSupplements] = useState<string[]>([]);

  useEffect(() => {
    let newOfferId = '';

    newOfferId = find_offer_id(region);

    if (newOfferId != offerId) {
      setOfferId(newOfferId);
    }
  }, [region]);

  useEffect(() => {
    const offer = cartBuilderOfferData3[offerId];
    const tier = find_tier(offer.products[0].merchandizedTiers, currentTierName);
    if (!tier) {
      setCurrentTierName(offer.products[0].merchandizedTiers[0].name);
    }
  }, [offerId]);

  useEffect(() => {
    const offer = cartBuilderOfferData3[offerId];
    const tier = find_tier(offer.products[0].merchandizedTiers, currentTierName);
    if (!tier) return;

    // Preserve the billing period selected if exists in the new tier; otherwise use the default (first)
    const changeBilling = true;
    const term = find_term(tier.prices, currentTermName);
    if (!term) {
      setCurrentTermName(tier.prices[0].billingPeriod);
    }
  }, [offerId, currentTierName]);

  useEffect(() => {
    const offer = cartBuilderOfferData3[offerId];
    const tier = find_tier(offer.products[0].merchandizedTiers, currentTierName);
    if (!tier) return;
    const term = find_term(tier.prices, currentTermName);
    if (!term) return;

    // Preserve the supplements if exists in the new price term
    let changeSupplements = false;
    let newSupplements = [...currentSupplements];

    for (let i = 0; i < currentSupplements.length; i++) {
      if (!term.supplements.some((item) => item.name.toLowerCase() === currentSupplements[i].toLowerCase())) {
        newSupplements = newSupplements.filter((value) => value.toLowerCase() != currentSupplements[i].toLowerCase());
        changeSupplements = true;
      }
    }

    setCurrentSupplements(newSupplements);
  }, [offerId, currentTierName, currentTermName]);

  const onRegionChange = (value: string) => {
    if (value === region) {
      return;
    }

    setRegion(value);
  };

  const renderRegions = (currentRegion: string) => {
    // Only needed for legal products
    const offer = cartBuilderOfferData3[offerId];
    if (offer.products[0].productFamily != 'Legal') {
      return <></>;
    }

    return (
      <>
        State:
        <Select onValueChange={onRegionChange} value={currentRegion}>
          {possibleRegions
            .filter((r) => !r.hidden)
            .map((reg) => (
              <SelectItem key={reg.value} value={reg.value.toString()}>
                {reg.label}
              </SelectItem>
            ))}
        </Select>
      </>
    );
  };

  const onTierChange = (value: string) => {
    if (value === currentTierName) {
      return;
    }

    setCurrentTierName(value);
  };

  const renderTiers = (currentTier: string, termName: string) => {
    const tiers = cartBuilderOfferData3[offerId].products[0].merchandizedTiers;

    //if (cartBuilderOfferData[offerId].products[0].merchandizedTiers.length === 1) {
    //  return <></>;
    //}

    const productId = cartBuilderOfferData3[offerId].products[0].id;
    const productVersion = cartBuilderOfferData3[offerId].products[0].productVersion;
    const simpleProductData = cartBuilderSimpleProductDataMap[productId + '|' + productVersion.toString()];

    if (!simpleProductData || !simpleProductData.tiers) return;
    const options: { label: string; value: string }[] = [];
    for (let i = 0; i < tiers.length; i++) {
      const tierName = tiers[i].name;
      let friendlyName = simpleProductData.tiers[tierName];
      let priceString = '';
      if (termName) {
        for (let j = 0; j < tiers[i].prices.length; j++) {
          if (tiers[i].prices[j].billingPeriod === termName) {
            priceString = get_price(tiers[i].prices[j].initialListPrice, termName);
            break;
          }
        }

        if (priceString) {
          friendlyName = friendlyName + ' @ ' + priceString;
        } else {
          friendlyName =
            friendlyName + ' @ ' + 'Not available ' + dd_term_strings[termName][pplsi.market.toLocaleLowerCase()];
        }
      }
      options.push({ label: friendlyName, value: tierName });
    }

    return (
      <>
        Tier:
        <Select onValueChange={onTierChange} value={currentTier}>
          {options.map((option) => (
            <SelectItem key={option.value} value={option.value.toString()}>
              {option.label}
            </SelectItem>
          ))}
        </Select>
      </>
    );
  };

  const onTermChange = (value: string) => {
    if (value === currentTermName) {
      return;
    }

    setCurrentTermName(value);
  };

  const renderTerms = (tierName: string, termName: string) => {
    const offer = cartBuilderOfferData3[offerId];

    if (!offer.products || offer.products.length != 1 || !offer.products[0].merchandizedTiers) return;

    let tier = null;
    for (let i = 0; i < offer.products[0].merchandizedTiers.length; i++) {
      if (offer.products[0].merchandizedTiers[i].name.toLowerCase() === tierName.toLowerCase()) {
        tier = offer.products[0].merchandizedTiers[i];
        break;
      }
    }
    if (tier === null) {
      tier = offer.products[0].merchandizedTiers[0];
    }

    //if (tier.prices.length === 1) {
    //  return <></>;
    //}

    const options: { label: string; value: string }[] = [];

    let price = 0;
    for (let i = 0; i < tier.prices.length; i++) {
      const lcm = pplsi.market?.toLocaleLowerCase();

      let label = dd_term_strings[tier.prices[i].billingPeriod][lcm];
      //if (tier.prices[i].billingPeriod.toLowerCase() != currentTermName.toLowerCase()) {
      label = label + ' @ ' + get_price(tier.prices[i].initialListPrice, tier.prices[i].billingPeriod);
      //}
      options.push({ label: label, value: tier.prices[i].billingPeriod });
      if (currentTermName.toLowerCase() === tier.prices[i].billingPeriod.toLowerCase()) {
        price = tier.prices[i].initialListPrice;
      }
    }

    let fees = 0;
    if (tier.fees) {
      for (const key in tier.fees) {
        fees = fees + tier.fees[key];
      }
    }

    return (
      <>
        Billing Terms:
        <Select onValueChange={onTermChange} value={termName}>
          {options.map((option) => (
            <SelectItem key={option.value} value={option.value.toString()}>
              {option.label}
            </SelectItem>
          ))}
        </Select>
      </>
    );
  };

  const onSupplementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newSupplements: string[] = [...currentSupplements];
    const name = e.target.name as string;
    if (newSupplements.includes(name)) {
      newSupplements = currentSupplements.filter((value) => value != name);
    } else {
      newSupplements.push(name);
    }
    setCurrentSupplements(newSupplements);
  };

  const get_price = (price: number, term: string): string => {
    if (!term) return '';
    return '$' + price.toFixed(2) + per_term_strings[term][pplsi.market?.toLowerCase()];
  };

  const renderSupplements = (tierName: string, termName: string, supplements: string[]) => {
    const offer = cartBuilderOfferData3[offerId];
    const productId = cartBuilderOfferData3[offerId].products[0].id;
    const productVersion = cartBuilderOfferData3[offerId].products[0].productVersion;
    const simpleProductData: CartBuilderProduct =
      cartBuilderSimpleProductDataMap[productId + '|' + productVersion.toString()];

    if (!offer.products || offer.products.length != 1 || !offer.products[0].merchandizedTiers) return;

    let tier = find_tier(offer.products[0].merchandizedTiers, tierName);

    if (tier === null) {
      tier = offer.products[0].merchandizedTiers[0];
    }

    if (!tier) return;

    // Find the pricing term
    const price = find_term(tier.prices, termName);
    if (!price) return;

    if (price.supplements === null) {
      return <></>;
    }

    const supplementElements = [];
    for (let i = 0; i < price.supplements.length; i++) {
      const name = price.supplements[i].name;
      const label =
        simpleProductData.supplements[price.supplements[i].name] +
        ' plus ' +
        get_price(price.supplements[i].initialListPrice, currentTermName);
      supplementElements.push(
        <div>
          <Checkbox name={name} rightLabel={label} checked={supplements.includes(name)} onChange={onSupplementChange} />
        </div>,
      );
    }

    return (
      <>
        Supplements:
        {supplementElements}
      </>
    );
  };

  const renderPrice = (tierName: string, termName: string, supplements: string[]) => {
    const offer = cartBuilderOfferData3[offerId];
    const productId = cartBuilderOfferData3[offerId].products[0].id;
    const productVersion = cartBuilderOfferData3[offerId].products[0].productVersion;
    const simpleProductData: CartBuilderProduct =
      cartBuilderSimpleProductDataMap[productId + '|' + productVersion.toString()];

    if (!offer.products || offer.products.length != 1 || !offer.products[0].merchandizedTiers) return;

    let tier = find_tier(offer.products[0].merchandizedTiers, tierName);

    if (tier === null) {
      tier = offer.products[0].merchandizedTiers[0];
    }

    if (!tier) return;

    // Find the price and the term
    let price = 0;
    let offerPrice: OfferPrice | null = null;
    for (let i = 0; i < tier.prices.length; i++) {
      if (termName.toLowerCase() === tier.prices[i].billingPeriod.toLowerCase()) {
        offerPrice = tier.prices[i];
        price = tier.prices[i].initialListPrice;
        break;
      }
    }

    if (offerPrice === null) {
      return (
        <>
          None
          <br />
        </>
      );
    }

    // Calcluate any fees
    let fees = 0;
    if (tier.fees) {
      for (const key in tier.fees) {
        fees = fees + tier.fees[key];
      }
    }

    for (let i = 0; i < offerPrice.supplements.length; i++) {
      const supplement: OfferSupplement = offerPrice.supplements[i];

      if (supplements.includes(supplement.name)) {
        price = price + supplement.initialListPrice;
      }
    }

    return (
      <>
        Price: {get_price(price, termName)}
        {fees != 0 && (
          <>
            <br />
            Fees: {get_price(fees, 'ONCE')}
          </>
        )}
        <br />
      </>
    );
  };

  const renderName = () => {
    const offer = cartBuilderOfferData3[offerId];
    const productId = offer.products[0].id;
    const productVersion = cartBuilderOfferData3[offerId].products[0].productVersion;
    const simpleProductData: CartBuilderProduct =
      cartBuilderSimpleProductDataMap[productId + '|' + productVersion.toString()];

    const name = simpleProductData.friendlyName;

    return (
      <>
        {name}:{offerId}
        <br />
      </>
    );
  };

  return (
    <div className="px-4">
      {renderName()}
      {renderPrice(currentTierName, currentTermName, currentSupplements)}
      {renderRegions(region)}
      {renderTiers(currentTierName, currentTermName)}
      {renderTerms(currentTierName, currentTermName)}
      {renderSupplements(currentTierName, currentTermName, currentSupplements)}
    </div>
  );
};

export const TestControl = forwardRef(TestControlComponent);

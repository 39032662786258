import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BusinessCard,
  Container,
  Icon,
  Layout,
  MarketingFooter,
  StickyHeading,
} from '@legalshield/adonis-ux-framework';
import { onCLS, onFID, onLCP } from 'web-vitals';

import 'react-loading-skeleton/dist/skeleton.css';
import './App.scss';

import { AppRoutes } from '../../routes/AppRoutes';
import { logger } from '../../utils/logger';

export type PlansProps = {
  cartBuilderItemIndex: number;
  setCartBuilderItemIndex: (num: number) => void;
};

const BackButton = (props: PlansProps) => {
  const { cartBuilderItemIndex, setCartBuilderItemIndex } = props;
  const goBack = () => {
    const prevItemIndex = cartBuilderItemIndex - 1;
    if (prevItemIndex >= 0 && cartBuilderData.items[prevItemIndex]) {
      setCartBuilderItemIndex(prevItemIndex);
    } else {
      history.go(-1);
    }
  };

  return (
    <div className="back-button py-4" style={{ paddingLeft: 'var(--dynamic-pad)', paddingRight: 'var(--dynamic-pad)' }}>
      <Icon onClick={goBack} name="arrow_left_lg" size="medium" />
    </div>
  );
};

export const App: FC = () => {
  const [cartBuilderItemIndex, setCartBuilderItemIndex] = useState<number>(0);
  const hideBackArrow =
    window.location.href.includes('/guideline') ||
    window.location.href.includes('/associate-terms') ||
    window.location.href.includes('/associate-agreement');

  useEffect(() => {
    ads_set_title(string_table.TITLE, true);
  }, []);

  // Track our location
  const location = useLocation();
  onCLS(logger.log);
  onFID(logger.log);
  onLCP(logger.log);
  useEffect(() => {
    onCLS(logger.log);
    onFID(logger.log);
    onLCP(logger.log);
    // onCLS(sendToGoogleAnalytics);
    // onFID(sendToGoogleAnalytics);
    // onLCP(sendToGoogleAnalytics);
  }, [location]);

  return (
    <>
      <Layout variant="none">
        <StickyHeading position="header" top="72px">
          <Container
            flexbox
            alignItems="center"
            background="light-gray"
            justifyContent={!hideBackArrow ? 'space-between' : 'flex-end'}
          >
            {(!hideBackArrow && (
              <BackButton
                cartBuilderItemIndex={cartBuilderItemIndex}
                setCartBuilderItemIndex={setCartBuilderItemIndex}
              />
            )) || <></>}
            {(associateData && associateData.siteLevel === 'ADVANCED' && (
              <BusinessCard
                market={pplsi.market?.toLocaleLowerCase()}
                envPrefix={pplsi.envPrefix}
                displayName={associateData.name}
                email={associateData.email}
                phone={associateData.phone}
                sitename={''}
                profileImage={associateData.avatarUrl}
              />
            )) || <></>}
          </Container>
        </StickyHeading>
        <div>
          <AppRoutes setCartBuilderItemIndex={setCartBuilderItemIndex} cartBuilderItemIndex={cartBuilderItemIndex} />
        </div>
      </Layout>
      <MarketingFooter
        classNames={['lsux-layout-padding']}
        social={false}
        footerContent={footerHtml}
        disclaimerContent={disclaimerHtml.replace(
          new RegExp('https://legalshieldassociate.com', 'g'),
          `https://${associateData?.sitename}.${pplsi.envPrefix}legalshieldassociate.com`,
        )}
      />
    </>
  );
};

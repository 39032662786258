import { CartBuilderData } from '../../types/globals';

type PlanItem = CartBuilderData['items'][0];

interface PriorityDictionary {
  [key: string]: number;
}

const prioritizePlanSort = <T extends PlanItem>(plan1: T, plan2: T) => {
  const cartBuilderItem1 = cartBuilderOfferData[plan1?.offers[0].offerId].products?.[0];
  const cartBuilderItem2 = cartBuilderOfferData[plan2.offers[0].offerId].products?.[0];
  const productFamilyA = cartBuilderItem1?.productFamily;
  const productFamilyB = cartBuilderItem2?.productFamily;
  const productTypeA = cartBuilderItem1?.productType;
  const productTypeB = cartBuilderItem2?.productType;

  // Define priorities
  const priorities: PriorityDictionary = {
    Associate: 3,
    Identity: 2,
    Legal: 1,
  };

  // Compare based on product family priorities
  const priorityA = priorities[productFamilyA || ''] || 4;
  const priorityB = priorities[productFamilyB || ''] || 4;

  if (priorityA !== priorityB) {
    return priorityA - priorityB;
  }

  // Within the same product family, compare based on product type
  if (productFamilyA === 'Legal') {
    const legalPriorities: PriorityDictionary = {
      'Legal CDLP': 3,
      'Legal Plan Family': 1,
      'Legal Small Business': 2,
      'Legal Super CDLP': 3,
      // Add more legal product types and priorities here
    };
    const legalPriorityA = legalPriorities[productTypeA || ''] || 4;
    const legalPriorityB = legalPriorities[productTypeB || ''] || 4;

    return legalPriorityA - legalPriorityB;
  } else if (productFamilyA === 'Identity') {
    const identityPriorities: PriorityDictionary = {
      'IDShield Family 2021': 1,
      'IDShield Individual 2021': 2,
      // Add more identity product types and priorities here
    };

    const identityPriorityA = identityPriorities[productTypeA || ''] || 3;
    const identityPriorityB = identityPriorities[productTypeB || ''] || 3;

    return identityPriorityA - identityPriorityB;
  }
  return 0;
};

/**
 * Sorts cartBuilderData.items according to item priority.
 */
export function initializeCartBuilder() {
  cartBuilderData.items = cartBuilderData?.items?.slice()?.sort(prioritizePlanSort);
  for (const [key, value] of Object.entries(cartBuilderOfferData)) {
    if (value?.products?.[0]?.productType === 'Legal Plan Family') {
      const cartBuilderOfferSupplements = value?.products?.[0]?.merchandizedTiers?.[0]?.prices?.[0]?.supplements || [];
      const homeBusinessIndex = cartBuilderOfferSupplements.findIndex((item) => item.name === 'homeBusiness_V2');
      const businessPlusIndex = cartBuilderOfferSupplements.findIndex((item) => item.name === 'businessPlus');
      if (homeBusinessIndex !== -1 && businessPlusIndex !== -1) {
        const offerData =
          cartBuilderOfferData[key || '']?.products?.[0]?.merchandizedTiers?.[0]?.prices?.[0]?.supplements;

        if (offerData) {
          offerData.splice(homeBusinessIndex + 1, 0, cartBuilderOfferSupplements[businessPlusIndex]);
          offerData.splice(businessPlusIndex, 1);
        }
      }
      break;
    }
  }
}

import React, { ReactNode } from 'react';
import { IPlanCardAddOnsProps, IPlanCardInfoProps, PlanCardInfo } from '@legalshield/adonis-ux-framework';

import envConfig from '../../config';
import { downcaseKeys, find_tier, getPerTerm } from '../../utils';
import { CartBuilderItem } from '../../view-models/cart-builder-item';

interface PlanCardInterface extends IPlanCardInfoProps, IPlanCardAddOnsProps {
  planFeesLabel: string;
  productFamily: string;
}

interface PlanInfoContainerProps {
  cartBuilderItem: CartBuilderItem;
  currentSupplements: string[];
  currentTermName: string;
  currentTierName: string;
  offerId: string;
  planPrice?: number;
  region: string;
  seeDetailText?: string;
  children: ReactNode | ReactNode[];
}

export const PlanInfoContainer: React.FC<PlanInfoContainerProps> = ({
  cartBuilderItem,
  children,
  currentSupplements,
  currentTermName,
  currentTierName,
  offerId,
  planPrice,
  region,
  seeDetailText,
}) => {
  const ASSOCIATE_AGREEMENT_PRODUCT_TYPE = 'Associate Agreement';
  const simpleProductData = cartBuilderItem.simpleProductDataForOfferId(offerId);
  const isAssociateAgreement =
    simpleProductData?.productType?.toLowerCase() === ASSOCIATE_AGREEMENT_PRODUCT_TYPE.toLowerCase();
  const planCardInfo: PlanCardInterface = {
    appName: simpleProductData?.productFamily === 'Identity' ? 'IDShield' : 'LegalShield',
    planFeesLabel: isAssociateAgreement ? string_table.FEE_LABEL_ASSOCIATE : '',
    planName: isAssociateAgreement ? string_table.ASSOCIATE_START_UP : simpleProductData?.friendlyName,
    planRecurrence: isAssociateAgreement ? `/${string_table.ONE_TIME_FEE}` : getPerTerm(currentTermName),
    planType: isAssociateAgreement ? '' : `${simpleProductData?.productFamily} Plan`,
    productFamily: simpleProductData?.productFamily || '',
  };

  const getContractIds = (): string => {
    const product = cartBuilderOfferData[offerId]?.products?.[0];
    if (!product) {
      return '';
    }
    const simpleProduct = cartBuilderSimpleProductDataMap[`${product.id}|${product.productVersion}`];
    if (!simpleProduct) {
      return '';
    }
    const contracts = simpleProduct.contracts;
    const baseRegionContract = contracts.product.byRegion[region];

    const tierContractIds = [
      contracts.tiers[currentTierName]?.defaultContract || contracts.tiers[currentTierName]?.byRegion[region],
    ];

    const standardizedContracts = downcaseKeys(contracts.supplements);

    const supplementContractIds = currentSupplements.flatMap((supplement) => [
      standardizedContracts[supplement.toLowerCase()]?.byRegion[region] ||
        standardizedContracts[supplement.toLowerCase()]?.defaultContract,
    ]);

    const contractIds = [baseRegionContract || contracts.product.defaultContract]
      .concat(tierContractIds)
      .concat(supplementContractIds)
      .filter((id) => id);
    return contractIds.join(',');
  };

  const handleSeeDetails = () => {
    const offer = cartBuilderOfferData[offerId];
    const product = offer?.products?.[0];
    const tier = find_tier(product?.merchandizedTiers || [], currentTierName);
    let url = `https://features.${pplsi.realmDomain}/product?id=${product?.id}&productFamily=${
      product?.productFamily
    }&productType=${product?.productType}&productVersion=${product?.productVersion}&tier=${
      tier?.name
    }&market=${pplsi.market?.toLocaleLowerCase()}`;
    if (currentSupplements.length) {
      url += `&supplements=${currentSupplements.toString()}`;
    }
    const contractsIds = getContractIds();
    if (contractsIds) {
      // note that contractsIds is the query param name, not contractIds
      url += `&contractsIds=${contractsIds}`;
    }
    window.open(url);
  };

  const buyNowKey = [
    cartBuilderOfferData[offerId]?.products?.[0].productFamily,
    cartBuilderOfferData[offerId]?.products?.[0].productType,
    cartBuilderOfferData[offerId]?.products?.[0].productVersion,
  ].join('|');

  const planDesc =
    (cartBuilderBuyNowOfferDataMapList?.find((item) => !!item[buyNowKey]?.description) || {})[buyNowKey]?.description ||
    '';

  const planName = planCardInfo.productFamily === 'Associate' ? string_table.ASSOCIATE : planCardInfo?.planName;
  let planType = string_table.BUYNOW_SUBTITLELEGALPLAN;
  if (planCardInfo.productFamily === 'Identity') {
    planType = string_table.BUYNOW_SUBTITLEIDENTITYPLAN;
  } else if (planCardInfo.productFamily === 'Associate') {
    planType = '';
  }
  return (
    <PlanCardInfo
      isSelected
      appName={planCardInfo?.appName}
      planFees={planCardInfo?.planFeesLabel}
      planName={planName}
      planPrice={`$${planPrice}`}
      planRecurrence={planCardInfo?.planRecurrence}
      planType={planType}
      showFees={!!planCardInfo?.planFeesLabel}
      showSeeDetails={planCardInfo?.productFamily !== envConfig.productFamily.associate && (region as string) !== ''}
      seeDetailText={seeDetailText}
      planDescription={planDesc}
      onSeeDetailsClick={handleSeeDetails}
      data-pplsi-event-id={'plan-card-summary-info'}
    >
      {children}
    </PlanCardInfo>
  );
};

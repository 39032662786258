const mockLog = {
  error(...args: Parameters<typeof console.error>) {
    return console.error(args);
  },
  group(..._args: Parameters<typeof console.group>) {},
  groupEnd(..._args: Parameters<typeof console.groupEnd>) {},
  log(..._args: Parameters<typeof console.log>) {},
};

export const logger = window.location.port || process.env.NODE_ENV === 'test' ? console : mockLog;

import React from 'react';
import { hydrate, render } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { AnalyticServiceVendor, AnalyticsOptions, ThemisAnalytics } from '@legalshield/frontend-analytics';

import './index.scss';

import { App } from './components/App/App';
import envConfig from './config';
import { FeatureContextProvider } from './contexts/feature-flag.context';
import { initializeCartBuilder } from './utils/sort';

const root = document.getElementById('root');
let renderMethod;
if (root && root.innerHTML !== '') {
  renderMethod = hydrate;
} else {
  renderMethod = render;
}
const analyticsConfig: AnalyticsOptions = {
  client: AnalyticServiceVendor.GoogleTagManager,
  config: {
    gtmService: envConfig.currentEnv.gtm,
  },
};
const queryClient = new QueryClient();
const themisAnalytics = new ThemisAnalytics(analyticsConfig);
export default themisAnalytics;

initializeCartBuilder();
themisAnalytics.initialize();
renderMethod(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <FeatureContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </FeatureContextProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

/* eslint-disable sort-keys */
/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable prettier/prettier */
import { CartBuilderData, OfferData } from '../../../types/globals';

export const cartBuilderData3: CartBuilderData = {
  "friendlyAssociateId": "127525095",
  "defaultRegion": null,
  "skipRegion": false,
  "items": [
      {
          "crossSell": false,
          "defaultSettings": {
              "selectedTier": null,
              "skipTier": false,
              "selectedTerm": null,
              "skipTerm": false,
              "supplements": []
          },
          "offers": [
              {
                  "offerId": "a815ccf2-72f0-4e27-9a63-5ff00181c05b",
                  "settings": {
                      "selectedTier": null,
                      "skipTier": false,
                      "selectedTerm": null,
                      "skipTerm": false,
                      "supplements": []
                  }
              },
              {
                  "offerId": "a815ccf2-72f0-4e27-9a63-5ff00181c05c",
                  "settings": {
                      "selectedTier": null,
                      "skipTier": false,
                      "selectedTerm": null,
                      "skipTerm": false,
                      "supplements": []
                  }
              }
          ]
      }
  ]
};

export const cartBuilderOfferData3: Record<string, OfferData> = {
  "a815ccf2-72f0-4e27-9a63-5ff00181c05b": {
    "id": "a815ccf2-72f0-4e27-9a63-5ff00181c05b",
    "commonName": "Small Business",
    "products": [
      {
        "id": "579e76a5-c011-4a48-8a05-60eb7850368c",
        "productType": "Legal Small Business",
        "productFamily": "Legal",
        "productVersion": 2,
        "merchandizedTiers": [
          {
            "fees": {"Setup": 25},
            "name": "Small Business Essentials",
            "prices": [
              {
                "supplements": [
                  {
                    "name": "businessPlus",
                    "initialListPrice": 1,
                    "renewalListPrice": 1
                  }
                ],
                "billingPeriod": "WEEKLY",
                "initialListPrice": 5,
                "renewalListPrice": 5
              },
              {
                "supplements": [
                  {
                    "name": "businessPlus",
                    "initialListPrice": 14.95,
                    "renewalListPrice": 14.95
                  }
                ],
                "billingPeriod": "MONTHLY",
                "initialListPrice": 49,
                "renewalListPrice": 49
              },
              {
                "supplements": [
                  {
                    "name": "businessPlus",
                    "initialListPrice": 149.5,
                    "renewalListPrice": 149.5
                  }
                ],
                "billingPeriod": "ANNUAL",
                "initialListPrice": 490,
                "renewalListPrice": 490
              }
            ]
          },
          {
            "fees": null,
            "name": "Small Business Plus",
            "prices": [
              {
                "supplements": [
                  {
                    "name": "businessPlus",
                    "initialListPrice": 14.95,
                    "renewalListPrice": 14.95
                  },
                  {
                    "name": "trialDefenseBusiness",
                    "initialListPrice": 14.95,
                    "renewalListPrice": 14.95
                  }
                ],
                "billingPeriod": "MONTHLY",
                "initialListPrice": 99,
                "renewalListPrice": 99
              },
              {
                "supplements": [
                  {
                    "name": "businessPlus",
                    "initialListPrice": 149.5,
                    "renewalListPrice": 149.5
                  },
                  {
                    "name": "trialDefenseBusiness",
                    "initialListPrice": 149.5,
                    "renewalListPrice": 149.5
                  }
                ],
                "billingPeriod": "ANNUAL",
                "initialListPrice": 990,
                "renewalListPrice": 990
              }
            ]
          },
          {
            "fees": null,
            "name": "Small Business Pro",
            "prices": [
              {
                "supplements": [
                  {
                    "name": "businessPlus",
                    "initialListPrice": 14.95,
                    "renewalListPrice": 14.95
                  },
                  {
                    "name": "trialDefenseBusiness",
                    "initialListPrice": 14.95,
                    "renewalListPrice": 14.95
                  }
                ],
                "billingPeriod": "MONTHLY",
                "initialListPrice": 149,
                "renewalListPrice": 149
              },
              {
                "supplements": [
                  {
                    "name": "businessPlus",
                    "initialListPrice": 39.95,
                    "renewalListPrice": 39.95
                  },
                  {
                    "name": "trialDefenseBusiness",
                    "initialListPrice": 39.95,
                    "renewalListPrice": 39.95
                  }
                ],
                "billingPeriod": "QUARTERLY",
                "initialListPrice": 400,
                "renewalListPrice": 400
              }
            ]
          }
        ],
        "productMinorVersion": 0
      }
    ],
    "availableLocalities": [
      {
        "name": "Alaska",
        "serviceArea": "1c7392ab-164e-4b68-bf7f-5c477718c4d0",
        "abbreviation": "AK",
        "requiresLicense": true
      },
      {
        "name": "Arizona",
        "serviceArea": "96ae15ec-7062-4472-9ff8-7f2f819551da",
        "abbreviation": "AZ",
        "requiresLicense": false
      },
      {
        "name": "Arkansas",
        "serviceArea": "c9277648-4198-43ae-ab68-13b35125297e",
        "abbreviation": "AR",
        "requiresLicense": true
      },
      {
        "name": "California One",
        "serviceArea": "1f7564f6-ddf3-4fca-9d9c-59403fc8dbbf",
        "abbreviation": "CA1",
        "requiresLicense": false
      },
      {
        "name": "California",
        "serviceArea": "1f7564f6-ddf3-4fca-9d9c-59403fc8dbbf",
        "abbreviation": "CA",
        "requiresLicense": false
      },
      {
        "name": "Colorado",
        "serviceArea": "6ce01652-64b7-4d95-8e8a-23f3564d52d4",
        "abbreviation": "CO",
        "requiresLicense": false
      },
      {
        "name": "Connecticut",
        "serviceArea": "1a04ce10-6951-4876-9b55-69b0fddd9532",
        "abbreviation": "CT",
        "requiresLicense": false
      },
      {
        "name": "Delaware",
        "serviceArea": "4ed63a33-fdbf-41d4-b894-680bb017995a",
        "abbreviation": "DE",
        "requiresLicense": false
      },
      {
        "name": "District of Columbia",
        "serviceArea": "a2438fac-4876-4af0-96e1-c38cb7b27964",
        "abbreviation": "DC",
        "requiresLicense": false
      },
      {
        "name": "Florida",
        "serviceArea": "57b59f68-deb6-4e5f-aac4-9582a6c40e15",
        "abbreviation": "FL",
        "requiresLicense": false
      },
      {
        "name": "Georgia",
        "serviceArea": "ddae960d-7567-4003-8e24-b9c98901f593",
        "abbreviation": "GA",
        "requiresLicense": false
      },
      {
        "name": "Hawaii",
        "serviceArea": "05b73568-f45a-4eed-88eb-f671d434f209",
        "abbreviation": "HI",
        "requiresLicense": false
      },
      {
        "name": "Idaho",
        "serviceArea": "32b0313a-ec5e-4b67-8fba-a0eb5d3b7751",
        "abbreviation": "ID",
        "requiresLicense": false
      },
      {
        "name": "Illinois",
        "serviceArea": "a554d7c0-2250-49c0-a54a-bb9ff171129b",
        "abbreviation": "IL",
        "requiresLicense": false
      },
      {
        "name": "Indiana",
        "serviceArea": "9e90da00-1b54-4d80-8d7d-5ac8c9fba225",
        "abbreviation": "IN",
        "requiresLicense": false
      },
      {
        "name": "Iowa",
        "serviceArea": "17a05c84-c63d-4737-b6c1-497427f89e76",
        "abbreviation": "IA",
        "requiresLicense": false
      },
      {
        "name": "Kansas",
        "serviceArea": "01996825-752c-4ec4-90d9-1db7aa5dc530",
        "abbreviation": "KS",
        "requiresLicense": false
      },
      {
        "name": "Kentucky",
        "serviceArea": "bd02a594-9023-4de6-a53f-8febb4bdf436",
        "abbreviation": "KY",
        "requiresLicense": false
      },
      {
        "name": "Louisiana",
        "serviceArea": "bd113c73-e8cc-4b81-b8cb-b146a3091751",
        "abbreviation": "LA",
        "requiresLicense": false
      },
      {
        "name": "Maine",
        "serviceArea": "58a4f252-bb30-4c59-92d0-718cbbcdddcc",
        "abbreviation": "ME",
        "requiresLicense": false
      },
      {
        "name": "Maryland",
        "serviceArea": "fe2026b3-51ba-4861-a839-17684fe2c9b5",
        "abbreviation": "MD",
        "requiresLicense": false
      },
      {
        "name": "Massachusetts",
        "serviceArea": "5eb0e471-cbe6-434d-b003-f7b937e613fd",
        "abbreviation": "MA",
        "requiresLicense": true
      },
      {
        "name": "Michigan",
        "serviceArea": "31e90574-ae9a-42fc-9383-2d098a4e60ca",
        "abbreviation": "MI",
        "requiresLicense": false
      },
      {
        "name": "Minnesota",
        "serviceArea": "b548a797-523e-43e9-9250-30eca14cc805",
        "abbreviation": "MN",
        "requiresLicense": false
      },
      {
        "name": "Mississippi",
        "serviceArea": "91bc34b2-3474-4293-8af1-bdb546e5672f",
        "abbreviation": "MS",
        "requiresLicense": false
      },
      {
        "name": "Missouri",
        "serviceArea": "8ce82537-8c3f-4315-9d31-a2d1c41be67e",
        "abbreviation": "MO",
        "requiresLicense": false
      },
      {
        "name": "Montana",
        "serviceArea": "3bb6f692-fdbe-4c67-9bb1-237afd250d06",
        "abbreviation": "MT",
        "requiresLicense": false
      },
      {
        "name": "Nebraska",
        "serviceArea": "5760f20f-50d3-4fb4-86c2-7040679d5938",
        "abbreviation": "NE",
        "requiresLicense": false
      },
      {
        "name": "Nevada",
        "serviceArea": "5e9e6435-130e-44b6-b998-f71b04c8cb9c",
        "abbreviation": "NV",
        "requiresLicense": false
      },
      {
        "name": "New Hampshire",
        "serviceArea": "b4e96e74-7302-458c-8745-1d672ada70fd",
        "abbreviation": "NH",
        "requiresLicense": false
      },
      {
        "name": "New Jersey",
        "serviceArea": "c1a6dd23-1eee-4d89-a454-3acfd4f8c33c",
        "abbreviation": "NJ",
        "requiresLicense": true
      },
      {
        "name": "New Mexico",
        "serviceArea": "4e088ed5-6027-4a73-9094-21e82a81e090",
        "abbreviation": "NM",
        "requiresLicense": false
      },
      {
        "name": "New York",
        "serviceArea": "e2e4d7ab-f6db-4e7b-bd30-cdf9ceab5368",
        "abbreviation": "NY",
        "requiresLicense": false
      },
      {
        "name": "North Carolina",
        "serviceArea": "e70c79c9-bd1d-4ac4-b0f7-4eae2c5b031f",
        "abbreviation": "NC",
        "requiresLicense": false
      },
      {
        "name": "North Dakota",
        "serviceArea": "4e3adc5c-01c5-427b-a5a4-9f9faee24a99",
        "abbreviation": "ND",
        "requiresLicense": true
      },
      {
        "name": "Ohio",
        "serviceArea": "19b5b248-e444-4e18-adb5-99e7573a85fd",
        "abbreviation": "OH",
        "requiresLicense": false
      },
      {
        "name": "Oklahoma",
        "serviceArea": "05b9668e-907a-4ade-9d62-d7d51398965b",
        "abbreviation": "OK",
        "requiresLicense": false
      },
      {
        "name": "Oregon",
        "serviceArea": "a65e5e13-482e-4802-b40e-ac4753a12b14",
        "abbreviation": "OR",
        "requiresLicense": false
      },
      {
        "name": "Pennsylvania",
        "serviceArea": "99b719cc-52aa-4b34-8db8-d0cb0ee82fe7",
        "abbreviation": "PA",
        "requiresLicense": false
      },
      {
        "name": "Rhode Island",
        "serviceArea": "6fe98bbb-502a-41ae-bea6-f9ff2f1ebea3",
        "abbreviation": "RI",
        "requiresLicense": false
      },
      {
        "name": "South Carolina",
        "serviceArea": "e6f2bc39-2430-4f9f-9848-85f0089966ff",
        "abbreviation": "SC",
        "requiresLicense": true
      },
      {
        "name": "South Dakota",
        "serviceArea": "1fe3ea24-528a-474e-9dfc-367e9b20f365",
        "abbreviation": "SD",
        "requiresLicense": false
      },
      {
        "name": "Tennessee",
        "serviceArea": "6cbfc871-ddf5-4832-bdeb-bd9a6ce6db5a",
        "abbreviation": "TN",
        "requiresLicense": false
      },
      {
        "name": "Texas",
        "serviceArea": "45ced3e5-5e82-4c19-bea8-31db1c2ac014",
        "abbreviation": "TX",
        "requiresLicense": false
      },
      {
        "name": "Utah",
        "serviceArea": "390faf6d-23aa-48e5-bd83-f309224aff9b",
        "abbreviation": "UT",
        "requiresLicense": false
      },
      {
        "name": "Vermont",
        "serviceArea": "08218d23-0307-4009-b44c-dccd38352e77",
        "abbreviation": "VT",
        "requiresLicense": false
      },
      {
        "name": "Virginia",
        "serviceArea": "55bbec65-2835-4989-b934-d7cc0b11b6d0",
        "abbreviation": "VA",
        "requiresLicense": true
      },
      {
        "name": "Washington",
        "serviceArea": "691f75b2-9907-4cc8-821a-4706bd882f41",
        "abbreviation": "WA",
        "requiresLicense": false
      },
      {
        "name": "West Virginia",
        "serviceArea": "99d07972-4bc0-454b-abeb-9a09a70989cf",
        "abbreviation": "WV",
        "requiresLicense": false
      },
      {
        "name": "Wisconsin",
        "serviceArea": "634bc5e6-0cd7-40ff-98e2-f6df551ff5ac",
        "abbreviation": "WI",
        "requiresLicense": true
      },
      {
        "name": "Wyoming",
        "serviceArea": "3cc7e454-b079-477c-936c-9de2c202bca5",
        "abbreviation": "WY",
        "requiresLicense": false
      }
    ]
  },
  "a815ccf2-72f0-4e27-9a63-5ff00181c05c": {
    "id": "a815ccf2-72f0-4e27-9a63-5ff00181c05c",
    "commonName": "Small Business",
    "products": [
      {
        "id": "579e76a5-c011-4a48-8a05-60eb7850368c",
        "productType": "Legal Small Business",
        "productFamily": "Legal",
        "productVersion": 2,
        "merchandizedTiers": [
          {
            "fees": {"Setup": 25},
            "name": "Small Business Essentials",
            "prices": [
              {
                "supplements": [
                  {
                    "name": "businessPlus",
                    "initialListPrice": 14.95,
                    "renewalListPrice": 14.95
                  }
                ],
                "billingPeriod": "MONTHLY",
                "initialListPrice": 49,
                "renewalListPrice": 49
              },
              {
                "supplements": [
                  {
                    "name": "businessPlus",
                    "initialListPrice": 149.5,
                    "renewalListPrice": 149.5
                  }
                ],
                "billingPeriod": "ANNUAL",
                "initialListPrice": 490,
                "renewalListPrice": 490
              }
            ]
          },
          {
            "fees": null,
            "name": "Small Business Plus",
            "prices": [
              {
                "supplements": [
                  {
                    "name": "businessPlus",
                    "initialListPrice": 14.95,
                    "renewalListPrice": 14.95
                  },
                  {
                    "name": "trialDefenseBusiness",
                    "initialListPrice": 14.95,
                    "renewalListPrice": 14.95
                  }
                ],
                "billingPeriod": "MONTHLY",
                "initialListPrice": 99,
                "renewalListPrice": 99
              }
            ]
          }
        ],
        "productMinorVersion": 0
      }
    ],
    "availableLocalities": [
      {"name":"Alabama","serviceArea":"3c1552b5-2ee7-4bac-9f33-a1c8dfdddc84","abbreviation":"AL","requiresLicense":false}
    ]
  },

};
import React from 'react';

export interface FeatureContextProps {
  /**
   * When multiselect=true is present as a query param on the initial render,
   * this value will be true.
   */
  multiselect: boolean;
}

export const FeatureContext = React.createContext({} as FeatureContextProps);

export const FeatureContextProvider: React.FC = ({ children }) => {
  // const searchParams = new URLSearchParams(location.search);
  // const multiselect: boolean = searchParams.get('multiselect') === 'true';
  const multiselect = true;

  return <FeatureContext.Provider value={{ multiselect }}>{children}</FeatureContext.Provider>;
};

import React, { FC } from 'react';
import {
  Button,
  Container,
  Grid,
  GridRow,
  Heading,
  Icon,
  List,
  ListItem,
  ListItemText,
  PlanCard,
  PlanCardInfo,
  Text,
  Title,
} from '@legalshield/adonis-ux-framework';

import './cross-sell.scss';

export const CrossSell: FC = () => {
  const features = [
    'interface_check',
    'Credit Identity',
    'Full Service Identity Restoration',
    'Cybersecurity and Device Monitoring',
    '$1 Million Protection',
  ];
  return (
    <Grid classNames={['crossSell']}>
      <Grid>
        <GridRow variant="center">
          <Container
            alignItems="center"
            background="light-gray"
            classNames={['m-4', 'cross-container']}
            justifyContent="center"
          >
            <Title title="Choose Membership" />
            <Heading as="T39" text="Need identity protection?" classNames={['title']} />
            <Text
              text="All membership plans come with a 30-day satisfaction guarantee."
              textSize="large"
              classNames={['title-description']}
            />
            <Grid classNames={['cross-sell-body ']}>
              <PlanCard>
                <PlanCardInfo
                  appName={'IDShield'}
                  planName={'IDShield'}
                  planPrice={`$${14.95}`}
                  planRecurrence={'/month starting'}
                  planType={'Identity Plan'}
                  showFees={false}
                  classNames={['plan-card-info']}
                >
                  <Grid classNames={['cross-sell-content']}>
                    <Grid classNames={['features']}>
                      <GridRow>
                        <Heading text="Included features:" classNames={['feat-head']} />
                      </GridRow>
                      <GridRow classNames={['feat-detail']}>
                        <List classNames={['feat-list']}>
                          <>
                            {features.map((feature, index) => {
                              return (
                                <ListItem key={index}>
                                  <Icon name="interface_check" classNames={['icon']} />
                                  <ListItemText primary={feature} />
                                </ListItem>
                              );
                            })}
                          </>
                        </List>
                      </GridRow>
                    </Grid>
                    <Grid classNames={['bottom']}>
                      <GridRow>
                        <Button label="Add ID Protection" stretch variant="primary" />
                      </GridRow>
                      <GridRow classNames={['skip-button-container']}>
                        <Button label="Skip for now" stretch variant="secondary" classNames={['skip-button']} />
                      </GridRow>
                    </Grid>
                  </Grid>
                </PlanCardInfo>
              </PlanCard>
            </Grid>
          </Container>
        </GridRow>
      </Grid>
    </Grid>
  );
};

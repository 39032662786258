import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Text } from '@legalshield/adonis-ux-framework';

import './guideline.scss';

export const Guideline = () => {
  const searchParams = new URLSearchParams(location.search);
  const data = searchParams.get('data');

  return (
    <Grid classNames={['guideline-container pt-8 pb-8']}>
      <Grid classNames={['guideline-sub-container pt-8 pb-8']}>
        <Grid>
          <Text classNames={['heading pt-6 pb-6']}>{string_table.MODAL_HEADING}</Text>
        </Grid>
        <Grid>
          <Text as="p" text={string_table.START_UP_MODAL_TEXT} />
          <ol className="description-points pl-4">
            <li>
              {`${string_table.MODAL_POINT_ONE}`}{' '}
              <Link to={`/AA.APP_8.21_4P.pdf?data=${data}`} target="_blank">
                {string_table.ASSOCIATE_AGREEMENT}
              </Link>
            </li>
            <li>
              {' '}
              {string_table.MODAL_POINT_TWO}
              <ul>
                <li>{string_table.MODAL_POINT_TWO_A}</li>
                <li>{string_table.MODAL_POINT_TWO_B}</li>
                <li>{string_table.MODAL_POINT_TWO_C}</li>
                <li>{string_table.MODAL_POINT_TWO_D}</li>
              </ul>
            </li>
            <li>
              {string_table.MODAL_POINT_THREE}
              <ul>
                <li>{string_table.MODAL_POINT_THREE_A}</li>
                <li>{string_table.MODAL_POINT_THREE_B}</li>
                <li>{string_table.MODAL_POINT_THREE_C}</li>
              </ul>
            </li>
          </ol>
          <Text>{string_table.START_UP_MODAL_BOTTOM_TEXT_ONE}</Text>
          <Text>{string_table.START_UP_MODAL_BOTTOM_TEXT_ONE_A}</Text>
          <Text as="p" classNames={['description']}>
            {string_table.START_UP_MODAL_BOTTOM_TEXT_TWO}
          </Text>
          <Text as="p" classNames={['description']}>
            <>
              {`${string_table.START_UP_MODAL_BOTTOM_TEXT_THREE} `}
              <b>{string_table.HELP_NO}</b>
            </>
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};

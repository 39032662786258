import React from 'react';
import { PlanCardBillingTerm } from '@legalshield/adonis-ux-framework';

import { dd_term_strings } from '../../../strings/PeriodStrings';
import { PlanConfigurationSetters } from '../../routes/AppRoutes';
import { get_price } from '../../utils';

interface BillingTermsProps {
  planConfigurationSetters: PlanConfigurationSetters;
  currentTermName: string;
  currentTierName: string;
  offerId: string;
  region: string;
  setAndScrollToRegionError: () => void;
}

export const BillingTerms: React.FC<BillingTermsProps> = ({
  planConfigurationSetters,
  currentTermName,
  currentTierName,
  offerId,
  region,
  setAndScrollToRegionError,
}) => {
  const offer = cartBuilderOfferData[offerId];
  if (!offer.products || offer.products.length != 1 || !offer.products[0].merchandizedTiers) return <></>;

  let tier = null;
  for (let i = 0; i < offer.products[0].merchandizedTiers.length; i++) {
    if (offer?.products?.[0]?.merchandizedTiers?.[i]?.name?.toLowerCase() === currentTierName.toLowerCase()) {
      tier = offer.products[0].merchandizedTiers[i];
      break;
    }
  }
  if (tier === null) {
    tier = offer.products[0].merchandizedTiers[0];
  }

  const options: string[] = [];

  for (let i = 0; i < (tier?.prices?.length || 0); i++) {
    const lcm = pplsi.market?.toLocaleLowerCase();

    let label = dd_term_strings[tier?.prices?.[i]?.billingPeriod || ''][lcm];
    label = label + ' @ ' + get_price(tier?.prices?.[i]?.initialListPrice || 0, tier?.prices?.[i]?.billingPeriod || '');
    options.push(tier?.prices?.[i]?.billingPeriod || '');
  }

  let fees = 0;
  if (tier.fees) {
    for (const key in tier.fees) {
      fees = fees + tier.fees[key];
    }
  }

  const onTermChange = (selectedTermIndex: number) => {
    if (!region) {
      return setAndScrollToRegionError();
    }
    if (options[selectedTermIndex] === currentTermName) {
      return;
    }
    planConfigurationSetters?.setCurrentTermName(options[selectedTermIndex]);
  };

  return (
    <div className="mt-5 billing-container">
      {(options?.length > 1 && (
        <PlanCardBillingTerm
          availableBillingTerms={(options ?? []).sort((a, b) => {
            const order = ['DAILY', 'WEEKLY', 'MONTHLY', 'QUARTERLY', 'ANNUAL'];
            const indexA = order.indexOf(a);
            const indexB = order.indexOf(b);
            return indexA - indexB;
          })}
          handleBillingTermClick={(index) => onTermChange(index)}
          selectedBillingTerm={currentTermName}
        />
      )) || <></>}
    </div>
  );
};
